import { useNavigate } from "react-router-dom";

import { ItemInfo } from "../../../domains/item/interface";
import useDeviceBreakPoint from "../../../hooks/useDeviceBreakPoint";
import ItemCard from "../../card/ItemCard";
import MobileMoreArrow from "../../mobile/icons/MobileMoreArrow";
import MoreArrow from "../icons/MoreArrow";

import EmptyList from "./EmptyList";

type Props = {
  title: string;
  list: ItemInfo[] | undefined;
  moreButtonLink: string;
  userNickname?: string;
  navigateState?: string;
};

const MAXIMUM_COUNT = 5;

export default function ItemGroupList({
  title,
  list,
  moreButtonLink,
  userNickname = "You",
  navigateState,
}: Props) {
  const navigate = useNavigate();
  const { isDesktop, isMobile } = useDeviceBreakPoint();

  const handleClickMore = () =>
    navigateState
      ? navigate(`${moreButtonLink}`, { state: { menu: navigateState } })
      : navigate(`${moreButtonLink}`);

  return (
    <section>
      <div className="flex items-center justify-between">
        <h3 className="w-[222px] md:w-fit truncate text-[18px] xl:text-[40px] font-semibold leading-[21px] xl:leading-[43px] pl-[18px] md:px-[24px] xl:pl-0">
          {title}
        </h3>
        {list && list.length >= MAXIMUM_COUNT && (
          <button
            className="pr-[18px] xl:pr-0"
            type="button"
            onClick={handleClickMore}
          >
            {isDesktop ? <MoreArrow /> : <MobileMoreArrow />}
          </button>
        )}
      </div>

      {(list === undefined || list.length === 0) && (
        <div className="mt-[30px] xl:mt-[120px]">
          <EmptyList type="item" title={userNickname} />
        </div>
      )}

      {list && list.length > 0 && (
        <ul className="flex gap-[8px] xl:gap-[50px] mt-[16px] xl:mt-[30px] px-[18px] md:px-[24px] xl:px-0 overflow-x-auto xl:overflow-x-hidden scrollbar-hidden">
          {list.slice(0, MAXIMUM_COUNT).map((item) => (
            <li key={item.itemId}>
              <ItemCard
                id={item.itemId}
                title={item.name}
                creator={item.uploaderName}
                uploaderId={item.uploaderId}
                thumbnailUrl={item.thumbnailUrl}
                spriteUrl={item.spriteUrl}
                avatarSrc={item.uploaderAvatarImageUrl}
                conversionStatus={item.conversionStatus}
                onClick={() => navigate(`/items/${item.itemId}`)}
                size="sm"
                rounded={isMobile ? "rounded-[10px]" : "rounded-[20px]"}
              />
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}
