import { Listbox } from "@headlessui/react";
import { Dispatch, SetStateAction } from "react";

import CategoryDividerIcon from "./icons/CategoryDividerIcon";
import SelectDropdownIcon from "./icons/SelectDropdownIcon";
import SmileIcon from "./icons/SmileIcon";

type SelectItem = {
  idx: string;
  name: string;
  unavailable?: boolean;
};

type Props = {
  list: SelectItem[];
  value: SelectItem;
  setValue: Dispatch<SetStateAction<SelectItem>>;
  columnStyle: "single" | "multiple";
  disabled?: boolean;
};

export default function SelectDropdown({
  list,
  value,
  setValue,
  columnStyle = "single",
  disabled,
}: Props) {
  return (
    <div>
      <Listbox value={value} onChange={setValue} disabled={disabled}>
        {({ open }) => (
          <>
            <div
              className={`min-w-52 box-border relative ${
                columnStyle === "multiple" ? "w-96" : "w-52"
              }`}
            >
              <Listbox.Button
                className={`flex flex-row items-center px-[20px] xl:px-10 relative bg-twinworldGray-680 text-white ${
                  open ? "rounded-t-[30px]" : "rounded-full"
                } ${columnStyle === "multiple" ? "w-96" : "w-52"} h-[64px]`}
              >
                <div
                  className={`${
                    columnStyle === "multiple"
                      ? "text-twinworldPrimary-200 min-w-52 w-fit"
                      : "text-white w-18"
                  }  flex items-center text-md text-ellipsis overflow-hidden h-6`}
                >
                  {columnStyle === "multiple" && (
                    <div className="mr-2">
                      <SmileIcon />
                    </div>
                  )}
                  <div className="text-[14px]">{value.name}</div>
                </div>
                <span
                  className={`${
                    columnStyle === "multiple" ? "ml-20" : "ml-10"
                  } h-6 flex items-center`}
                >
                  <div
                    className={`${
                      open ? "rotate-180 transition-all" : "transition-all"
                    }`}
                  >
                    <SelectDropdownIcon columnStyle={columnStyle} />
                  </div>
                </span>
              </Listbox.Button>
              {open && (
                <Listbox.Options
                  static
                  className={`absolute z-50 top-14 bg-twinworldGray-680 text-white min-w-52 w-full px-10 ${
                    open ? "rounded-b-[30px]" : "rounded-full"
                  } ${columnStyle === "multiple" ? "max-h-96 flex-wrap" : ""}`}
                >
                  <CategoryDividerIcon columnStyle={columnStyle} />
                  <div
                    className={`py-[27px] box-border w-full flex flex-col gap-6 ${
                      columnStyle === "multiple"
                        ? "flex-wrap max-h-[389px]"
                        : "scrollbar"
                    }`}
                  >
                    {list.map((element, elementIdx) => (
                      <Listbox.Option
                        key={element.idx}
                        className={({ active }) =>
                          `flex cursor-pointer truncate w-fit text-twinworldGray-450 hover:text-white text-[14px] leading-[16.8px] ${
                            active && "text-white"
                          }`
                        }
                        value={element}
                        disabled={element.unavailable}
                      >
                        <span className="overflow-hidden text-ellipsis">
                          {element.name}
                        </span>
                      </Listbox.Option>
                    ))}
                  </div>
                </Listbox.Options>
              )}
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}
