import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

export const copyToClipboard = (value: string) => {
  navigator.clipboard
    .writeText(value)
    .then(() => true)
    .catch(() => false);

  return true;
};

export const dateToTimeAgo = (date: Date) => {
  dayjs.extend(relativeTime);
  return dayjs().to(dayjs(date));
};

export const generateNumberArrayAsc = (start: number, end: number) =>
  [...Array(end - start + 1)].map((_, index) => start + index);

export const generateNumberArrayDesc = (start: number, end: number) =>
  [...Array(end - start + 1)].map((_, index) => end - index);

export const getBirthDateOptions = () => {
  const yearOptions = [
    { idx: "year", name: "Year", unavailable: true },
    ...generateNumberArrayDesc(1923, 2022).map((year) => ({
      idx: `${year}_year`,
      name: `${year}`,
    })),
  ];

  const monthOptions = [
    { idx: "month", name: "Month", unavailable: true },
    ...generateNumberArrayAsc(1, 12).map((month) => ({
      idx: `${month}_month`,
      name: `${month}`,
    })),
  ];

  const dayOptions = [
    { idx: "day", name: "Day", unavailable: true },
    ...generateNumberArrayAsc(1, 31).map((day) => ({
      idx: `${day}_day`,
      name: `${day}`,
    })),
  ];

  return {
    yearOptions,
    monthOptions,
    dayOptions,
  };
};

/**
 * 문자열에 특수문자가 포함되었는지 확인하는 함수
 * @param {string} word 문자열
 * @returns {boolean} 특수문자가 포함 true, 없음 false 를 리턴
 */
export function checkSpecialChar(word: string) {
  // 특수문자 정규식
  const expText = /[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/gi;
  return expText.test(word);
}

/**
 * word에 특수문자 제거 후 마지막 공백 제거 함수
 * @param {string} word 문자열
 * @returns  {string} 특수문자와 마지막 공백이 제거된 문자열
 */
export function filterInvalidateKeyword(word: string) {
  if (!!word && checkSpecialChar(word)) {
    const expText = /[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/gi;
    const filteredWord = word.replace(expText, "");

    // keyword에 특수문자가 섞였다면 특수문자 제거
    // keyword가 특수문자만 있다면 그대로
    if (filteredWord) {
      word = filteredWord;
    }
  }

  return word.trimEnd();
}

export function getByteSize(text: string) {
  let byteSize = 0;

  for (let i = 0; i < text.length; i += 1) {
    const char = text.charAt(i);
    if (escape(char).length > 4) {
      byteSize += 2;
    } else {
      byteSize += 1;
    }
  }

  return byteSize;
}
