import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/header/Header";
import Landing from "../components/landing";
import MobileFooter from "../components/mobile/MobileFooter";
import MobileLanding from "../components/mobile/MobileLanding";
import CheckEmailModal from "../components/modal/CheckEmailModal";
import LinkExpiredModal from "../components/modal/LinkExpiredModal";
import ResetPasswordModal from "../components/modal/ResetPasswordModal";
import ResetPwSuccessModal from "../components/modal/ResetPwSuccessModal";
import SignInModal from "../components/modal/SignInModal";
import SignUpSuccessModal from "../components/modal/SignUpSuccessModal";
import useDeviceBreakPoint from "../hooks/useDeviceBreakPoint";

const Main = () => {
  const [isOpenResetPwSuccessModal, setIsOpenResetPwSuccessModal] =
    useState(false);
  const { isDesktop } = useDeviceBreakPoint();

  const navigate = useNavigate();
  const { hash } = useLocation();

  const handleResetPwSuccessModal = () => {
    setIsOpenResetPwSuccessModal(true);
  };

  return (
    <div
      className={`flex flex-col justify-center min-w-[375px] md:w-full xl:min-w-[1440px] overflow-x-auto md:overflow-x-hidden`}
    >
      <Header />

      <main className="w-full xl:min-w-[1440px] xl:max-w-[1920px] mx-auto overflow-hidden">
        {isDesktop ? <Landing /> : <MobileLanding />}
      </main>

      {isDesktop ? <Footer /> : <MobileFooter />}

      {hash === "#success" && (
        <SignUpSuccessModal
          isOpen={hash === "#success"}
          closeModal={() => navigate("/")}
        />
      )}
      {hash === "#expired-email-verification" && (
        <LinkExpiredModal
          type="email-verification"
          isOpen={hash === "#expired-email-verification"}
          closeModal={() => navigate("/")}
        />
      )}
      {hash === "#email-verification" && (
        <CheckEmailModal
          isOpen={hash === "#email-verification"}
          closeModal={() => navigate("/")}
        />
      )}
      {hash === "#expired-reset-password" && (
        <LinkExpiredModal
          type="reset-password"
          isOpen={hash === "#expired-reset-password"}
          closeModal={() => navigate("/")}
        />
      )}
      {hash === "#confirmed" && (
        <ResetPasswordModal
          isOpen={hash === "#confirmed"}
          closeModal={() => navigate("/")}
          handleResetPwSuccessModal={handleResetPwSuccessModal}
        />
      )}
      {hash === "#signin" && (
        <SignInModal
          isOpen={hash === "#signin"}
          closeModal={() => navigate("/")}
        />
      )}
      {isOpenResetPwSuccessModal && (
        <ResetPwSuccessModal
          isOpen={isOpenResetPwSuccessModal}
          closeModal={() => setIsOpenResetPwSuccessModal(false)}
        />
      )}
    </div>
  );
};

export default Main;
