import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import MainWrapper from "../components/common/MainWrapper";
import ItemGroupList from "../components/common/list/ItemGroupList";
import SpaceGroupList from "../components/common/list/SpaceGroupList";
import ProfileMenu from "../components/menu/ProfileMenu";
import ProfileSubMenu from "../components/menu/ProfileSubMenu";
import UserDetails from "../components/profile/UserDetails";
import UserItemList from "../components/profile/UserItemList";
import UserSpaceList from "../components/profile/UserSpaceList";
import { useAuthContext } from "../contexts/AuthContext";
import { useItemListActions } from "../domains/item/hook/useItemListAction";
import { useSpaceActions } from "../domains/space/hook/useSpaceActions";
import { SpaceType } from "../domains/space/interface";

const DEFAULT_SPACE_TYPE = "created";
const DEFAULT_ITEM_TYPE = "my-medias";

type Location = {
  state: {
    menu: string;
  };
};

const MENU_LIST = [{ label: "home" }, { label: "spaces" }, { label: "items" }];

export const MyProfile = () => {
  const { profile } = useAuthContext();
  const { state } = useLocation() as Location;

  const [menu, setMenu] = useState("home");
  const [subMenu, setSubMenu] = useState("");
  const [spaceType, setSpaceType] = useState(DEFAULT_SPACE_TYPE);
  const [itemType, setItemType] = useState("my-medias");

  const { useInfiniteSearchSpaceList } = useSpaceActions();
  const { data: spaceData, fetchNextPage: fetchNextSpacePage } =
    useInfiniteSearchSpaceList(spaceType as SpaceType, "", 24, 0);

  const { useInfiniteItemList } = useItemListActions();
  const { data: itemData, fetchNextPage: fetchNextItemPage } =
    useInfiniteItemList(itemType, "", "all", 24, 0);

  useEffect(() => {
    if (state) {
      setMenu(state.menu);
    }
  }, [state]);

  useEffect(() => {
    if (menu === "spaces") {
      setSpaceType(subMenu);
    } else if (menu === "items") {
      setItemType(subMenu);
    }
  }, [subMenu]);

  useEffect(() => {
    if (menu === "spaces") {
      setSubMenu(DEFAULT_SPACE_TYPE);
    } else if (menu === "items") {
      setSubMenu(DEFAULT_ITEM_TYPE);
    }
  }, [menu]);

  return (
    <MainWrapper>
      <div className="flex flex-col w-full">
        <UserDetails
          selectedUserProfile={profile}
          loggedInUserId={profile?.userId}
        />

        <div className="flex flex-col items-center justify-center w-full gap-4 xl:gap-8 mt-[42px] xl:mt-16">
          <ProfileMenu
            menuList={MENU_LIST}
            selectedMenu={menu}
            setSelectedMenu={setMenu}
          />
          {menu !== "home" && (
            <ProfileSubMenu
              type={menu}
              selectedSubMenu={subMenu}
              setSelectedSubMenu={setSubMenu}
            />
          )}
        </div>
      </div>

      <div className="flex flex-col w-full gap-[64px] xl:gap-24 mt-[42px] xl:mt-[62px]">
        {menu === "home" && (
          <>
            <SpaceGroupList
              title="Spaces"
              list={spaceData?.pages[0]}
              moreButtonLink={"/spaces?type=trend"}
              userNickname={profile?.nickname}
            />
            <ItemGroupList
              title="Items"
              list={itemData?.pages[0]}
              moreButtonLink={"/items?type=recommended-list"}
              userNickname={profile?.nickname}
            />
          </>
        )}
        {menu === "spaces" && (
          <UserSpaceList
            title={profile?.nickname}
            list={spaceData}
            fetchNextPage={fetchNextSpacePage}
            subMenu={subMenu}
          />
        )}
        {menu === "items" && (
          <UserItemList
            title={profile?.nickname}
            list={itemData}
            fetchNextPage={fetchNextItemPage}
            subMenu={subMenu}
          />
        )}
      </div>
    </MainWrapper>
  );
};
