import toast, { Toaster } from "react-hot-toast";

import CheckIcon from "../common/icons/CheckIcon";

import MobileSharedIcon from "./icons/MobileSharedIcon";

const customToast = () => {
  toast.custom(
    (t) => (
      <div
        style={{
          whiteSpace: "nowrap",
          display: "flex",
          alignItems: "center",
          gap: "5px",
          border: "1px solid black",
          padding: "8px 16px",
          borderRadius: "5px",
          boxShadow: "3px 3px 3px 0px rgba(0, 0, 0, .25)",
          position: "absolute",
          right: "25px",
          top: "-6px",
          backgroundColor: "white",
          opacity: t.visible ? 1 : 0,
          transition: "opacity 1s ease-in-out",
        }}
      >
        <div className="absolute w-[15px] -right-[15px] top-[8px] z-10 overflow-hidden flex items-center">
          <div className="w-[10px] h-[25px] origin-top-left transform rotate-45 bg-white border border-black" />
        </div>
        <CheckIcon />
        <span className="ml-[6px] text-[14px]">Copy link to clipboard</span>
      </div>
    ),
    {
      duration: 500,
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    }
  );
};

export default function MobileSharedLinkToast() {
  const pageLink = window.location.href;

  return (
    <div
      className="relative flex cursor-pointer"
      onClick={async () => {
        await navigator.clipboard.writeText(pageLink);
        customToast();
      }}
    >
      <MobileSharedIcon />
      <Toaster
        containerStyle={{
          position: "absolute",
          top: 0,
          right: 13,
          width: "fit-content",
        }}
        containerClassName="toaster-wrapper"
      />
    </div>
  );
}
