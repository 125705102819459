import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { ItemInfo } from "../../domains/item/interface";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import { useInfiniteScrollRef } from "../../hooks/useInfiniteScrollRef";
import ItemCard from "../card/ItemCard";
import ScrollToTopButton from "../common/Button/ScrollToTopButton";
import EmptyList from "../common/list/EmptyList";
import ItemListWrapper from "../layout/ItemListWrapper";

type Props = {
  title?: string;
  list: InfiniteData<ItemInfo[]> | undefined;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<any, unknown>>;
  subMenu?: string;
};

export default function UserItemList({
  title = "You",
  list,
  fetchNextPage,
  subMenu,
}: Props) {
  const { targetRef } = useInfiniteScrollRef({ fetchNextPage });
  const { isMobile } = useDeviceBreakPoint();
  const navigate = useNavigate();

  const handleClickCard = (itemId: string) => navigate(`/items/${itemId}`);

  return (
    <ItemListWrapper isEmptyData={!list?.pages[0].length}>
      {list && list.pages[0].length > 0 ? (
        list?.pages.map((page, pageIndex) => (
          <Fragment key={pageIndex}>
            {page.map((item) => (
              <ItemCard
                key={item.itemId}
                id={item.itemId}
                title={item.name}
                thumbnailUrl={item.thumbnailUrl}
                spriteUrl={item.spriteUrl}
                creator={item.uploaderName}
                uploaderId={item.uploaderId}
                avatarSrc={item.uploaderAvatarImageUrl}
                conversionStatus={item.conversionStatus}
                onClick={() => handleClickCard(item.itemId)}
                rounded={isMobile ? "rounded-[14px]" : "rounded-[20px]"}
              />
            ))}
          </Fragment>
        ))
      ) : (
        <div className="flex flex-col items-center justify-center w-full mx-auto text-center xl:mt-32 text-twinworldGray-680">
          <EmptyList type="item" title={title} subTab={subMenu} />
        </div>
      )}
      <div ref={targetRef} />
      <ScrollToTopButton />
    </ItemListWrapper>
  );
}
