import { useEffect, useState } from "react";

import UpArrow from "../icons/UpArrow";

const VISIBLE_SCROLL_BAR_DISTANCE = 300;

export default function ScrollToTopButton() {
  const [isHovered, setIsHovered] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const handleScroll = () => {
    const { scrollTop } = document.documentElement;
    if (scrollTop > VISIBLE_SCROLL_BAR_DISTANCE) {
      setIsButtonVisible(true);
    } else {
      setIsButtonVisible(false);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsHovered(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button
      className={`fixed right-10 3xl:right-40 bottom-10 flex flex-col items-center justify-center 
      transition-opacity ease-in-out rounded-full ${
        isButtonVisible ? "opacity-100" : "opacity-0"
      } w-11 h-11 ${
        isHovered
          ? "border-none bg-twinworldGray-700"
          : "border-2 border-black bg-white"
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      <UpArrow isHovered={isHovered} />
    </button>
  );
}
