import useLazyLoadImage from "../../../../hooks/useLazyLoadImage";
import MobileDeviceShadowIcon from "../../icons/MobileDeviceShadowIcon";

type Props = {
  imageName: string;
};

export default function MobileDeviceCarouselItem({ imageName }: Props) {
  const ref = useLazyLoadImage();
  const name = imageName.replace(/_/g, " ");

  return (
    <div
      ref={ref}
      className="flex flex-col items-center justify-start w-[140px] sm:w-[180px]"
    >
      <picture>
        <source
          data-srcset={`/images/landing/devices/${imageName}@2x.webp 2x, /images/landing/devices/${imageName}.webp 1x`}
          type="image/webp"
        />
        <img
          data-src={`/images/landing/devices/${imageName}.webp`}
          alt={name}
        />
      </picture>
      <MobileDeviceShadowIcon />
    </div>
  );
}
