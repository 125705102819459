import { useState } from "react";

import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import ImageComponent from "../common/Image";
import SpriteImage from "../common/SpriteImage";
import { ConversionStatus } from "../item/type";

import ConversionStatusThumbnail from "./ConversionStatusThumbnail";

type Props = {
  isHovered: boolean;
  src: string;
  width: string;
  height: string;
  alt: string;
  defaultSrc?: string;
  spriteUrl: string;
  border?: boolean;
  rounded?: string;
  conversionStatus: ConversionStatus;
};

export default function ItemThumbnail({
  isHovered,
  src,
  width,
  height,
  alt,
  defaultSrc,
  spriteUrl,
  border = false,
  rounded = "none",
  conversionStatus = "success",
}: Props) {
  const [isSpriteExist, setIsSpriteExist] = useState(false);
  const [isCheckImageExist, setIsCheckImageExist] = useState(false);

  const { isDesktop } = useDeviceBreakPoint();

  const checkImageExists = (url: string) => {
    const img = new Image();
    img.onload = () => setIsSpriteExist(true);
    img.onerror = () => setIsSpriteExist(false);
    img.src = url;

    setIsCheckImageExist(true);
  };

  if (conversionStatus !== "success") {
    return (
      <ConversionStatusThumbnail
        type="card"
        conversionStatus={conversionStatus}
        width={width}
        height={height}
        rounded={rounded}
      />
    );
  }

  if (isHovered && !isCheckImageExist) {
    checkImageExists(spriteUrl);
  }

  if (isHovered && isSpriteExist && isDesktop) {
    return (
      <section className="relative flex items-center justify-center">
        <SpriteImage src={spriteUrl} widthOfImage={248} heightOfImage={248} />
      </section>
    );
  }

  return (
    <ImageComponent
      width={width}
      height={height}
      src={src}
      alt={alt}
      defaultSrc={defaultSrc}
      hoverEffect={"xl:hover:border-[#2C4B99] xl:hover:border-2"}
      border={border}
      rounded={rounded}
    />
  );
}
