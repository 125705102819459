import { Profile, UserInfo } from "../interface";

class User implements UserInfo {
  userId: string;

  nickname: string;

  description: string;

  avatarUrl: string;

  avatarImageUrl: string;

  constructor(data: Profile) {
    const { userId, nickname, description, avatarUrl, avatarImageUrl } = data;

    this.userId = userId;
    this.nickname = nickname;
    this.description = description;
    this.avatarUrl = avatarUrl;
    this.avatarImageUrl = avatarImageUrl;
  }
}

export default User;
