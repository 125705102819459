import { useState } from "react";
import { Link } from "react-router-dom";

import { useAuthContext } from "../../contexts/AuthContext";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import MainLogo from "../common/icons/MainLogo";
import MobileUserMenu from "../mobile/header/MobileUserMenu";

import HeaderDim from "./HeaderDim";
import HeaderMenu from "./HeaderMenu";
import UserMenu from "./UserMenu";

export default function Header() {
  const { profile } = useAuthContext();
  const { isDesktop, isMobile } = useDeviceBreakPoint();

  const [isDropdownMenuActive, setIsDropdownMenuActive] = useState(false);
  const [isMobileGnbMenuActive, setIsMobileGnbMenuActive] = useState(false);

  const handleDropdownMenuToggle = () => {
    setIsDropdownMenuActive(!isDropdownMenuActive);

    if (isMobileGnbMenuActive) setIsMobileGnbMenuActive(false);
  };

  const handleMobileGnbMenuToggle = () => {
    setIsMobileGnbMenuActive(!isMobileGnbMenuActive);

    if (isDropdownMenuActive) setIsDropdownMenuActive(false);
  };

  return (
    <header className="min-w-[375px] xl:mx-auto xl:min-w-[1440px]">
      <HeaderDim
        isDropdownMenuActive={isDropdownMenuActive}
        isMobileGnbMenuActive={isMobileGnbMenuActive}
      />

      <div className="flex justify-between items-center py-[25px] xl:py-[16px] px-[18px] xl:px-[16px]">
        <Link className="h-fit w-fit" to="/">
          <MainLogo width={isMobile ? 150 : 200} height={isMobile ? 36 : 50} />
        </Link>

        {isDesktop ? (
          <>
            <HeaderMenu />
            <UserMenu
              profile={profile}
              isDropdownMenuActive={isDropdownMenuActive}
              handleDropdownMenuToggle={handleDropdownMenuToggle}
            />
          </>
        ) : (
          <MobileUserMenu
            profile={profile}
            isDropdownMenuActive={isDropdownMenuActive}
            isMobileGnbMenuActive={isMobileGnbMenuActive}
            handleDropdownMenuToggle={handleDropdownMenuToggle}
            handleMobileGnbMenuToggle={handleMobileGnbMenuToggle}
          />
        )}
      </div>
    </header>
  );
}
