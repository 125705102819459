import { useNavigate, useLocation } from "react-router-dom";

type Menu = {
  key: string;
  label: string;
  action: () => void;
};

export default function HeaderMenu() {
  const navigate = useNavigate();
  const location = useLocation();

  const MENU_LIST: Menu[] = [
    {
      key: "space",
      label: "Space",
      action: () => navigate("/spaces?type=trend"),
    },
    {
      key: "item",
      label: "Item",
      action: () => navigate("/items?type=recommended-list"),
    },
    {
      key: "community",
      label: "Community",
      action: () => window.open("https://discord.com/invite/3qVZgBGrxr"),
    },
    {
      key: "support",
      label: "Support",
      action: () => window.open("https://www.doubleme.me/contact"),
    },
    {
      key: "press",
      label: "Press",
      action: () => window.open("https://www.doubleme.me/press"),
    },
  ];

  const getActiveLocation = (key: string) => {
    if (
      (location.pathname.includes("items") && key === "item") ||
      (location.pathname.includes("spaces") && key === "space")
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className="hidden xl:flex xl:items-center">
      <ul className="flex items-center justify-center text-[16px] leading-4 font-medium gap-[5px]">
        {MENU_LIST.map((menu: Menu) => (
          <li
            key={menu.key}
            className={`px-[25px] py-2.5 cursor-pointer transition ease-in-out duration-300 rounded-full hover:bg-[#EFF8FF] min-w-[68px] box-border ${
              getActiveLocation(menu.key) && "bg-[#EFF8FF] rounded-full"
            }`}
            onClick={menu.action}
          >
            {menu.label}
          </li>
        ))}
      </ul>
    </div>
  );
}
