import { RefObject } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthActions } from "../../domains/auth/hook/useAuthActions";
import { UserInfo } from "../../domains/user/interface";
import { useOutsideDetectRef } from "../../hooks/useOutsideDetectRef";
import Avatar from "../common/Avatar";

type Props = {
  profile: UserInfo | undefined;
  isActive: boolean;
  setIsActive: () => void;
};

export default function MobileGnbDropdownMenu({
  profile,
  isActive,
  setIsActive,
}: Props) {
  const navigate = useNavigate();

  const { signOut } = useAuthActions();

  const SETTING_MENU_LIST = [
    {
      key: "profile",
      label: "Profile",
      action: () => navigate("/myprofile", { state: { menu: "home" } }),
    },
    {
      key: "pinCode",
      label: "Pin Code",
      action: () => {
        handleClickMenu(() => navigate("/qr-sign-in"), setIsActive);
      },
    },
  ];

  const MENU_LIST = [
    {
      key: "mySpace",
      label: "My Space",
      action: () => navigate("/myprofile", { state: { menu: "spaces" } }),
    },
    {
      key: "myItem",
      label: "My Item",
      action: () => navigate("/myprofile", { state: { menu: "items" } }),
    },
    {
      key: "mycoins",
      label: "My Coins",
      action: () => navigate("/mycoins", { state: { menu: "redeem" } }),
    },
    {
      key: "avatar",
      label: "Avatar",
      action: () => navigate("/setting", { state: { menu: "avatar" } }),
    },
    {
      key: "setting",
      label: "Settings",
      action: () => navigate("/setting", { state: { menu: "profile" } }),
    },
  ];

  const handleClickMenu = (action: () => void, callback?: () => void) => {
    action();
    callback?.();
  };

  const dropdownRef: RefObject<HTMLDivElement> = useOutsideDetectRef(
    setIsActive,
    isActive
  );

  return (
    <div
      className="flex flex-col items-center justify-end w-full min-w-[375px] py-[28px] bg-white absolute z-50 top-[89px] left-0 right-0 box-border"
      ref={dropdownRef}
    >
      {profile && (
        <>
          <Avatar size="xl" src={profile.avatarImageUrl} />
          <div className="flex flex-col items-center mt-[12px] gap-[7px] leading-[22px]">
            <span
              className="text-[18px] text-twinworldGray-800 font-medium max-w-[220px] truncate"
              key={profile.nickname}
            >
              {profile.nickname}
            </span>
          </div>
          <div className="flex flex-row gap-2.5 mt-5 font-medium">
            {SETTING_MENU_LIST.map((link) => (
              <button
                className="box-border px-[20px] py-2.5 rounded-full border border-twinworldGray-250 bg-twinworldGray-150 text-[14px] font-medium"
                key={link.key}
                onClick={() => {
                  handleClickMenu(link.action, setIsActive);
                }}
              >
                {link.label}
              </button>
            ))}
          </div>
          <div className="flex flex-col w-full gap-5 pl-[36px] mt-[26px] py-[26px] text-twinworldGray-900 text-[18px] leading-[18px] font-medium border-y border-y-twinworldGray-150">
            {MENU_LIST.map((link) => (
              <div
                key={link.key}
                className="flex items-center cursor-pointer w-max"
              >
                <button
                  type="button"
                  onClick={() => {
                    handleClickMenu(link.action, setIsActive);
                  }}
                >
                  {link.label}
                </button>
              </div>
            ))}
          </div>
          <div className="flex flex-col w-full pt-[26px] pl-[36px] text-[18px] leading-[18px] font-medium text-twinworldGray-900">
            <div className="cursor-pointer w-max" onClick={signOut}>
              <div>Sign Out</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
