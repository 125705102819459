const ThumbnailImageGuide = () => (
  <section className="leading-relaxed text-black mt-[62px] font-poppins xl:font-nexon pl-6 pr-[18px] xl:px-0">
    <p className="font-semibold text-[24px] xl:text-[40px] leading-[31px] font-poppins max-w-[200px] xl:max-w-full">
      Item thumbnail image guide
    </p>

    <p className="font-semibold xl:font-medium text-[20px] xl:text-[24px] mt-[36px] xl:mt-[70px] text-twinworldOrange font-poppins">
      Item Thumbnail Guidelines
    </p>

    <div className="xl:ml-[30px] mt-[24px] xl:mt-4">
      <p className="text-[16px] xl:text-[20px] font-semibold xl:font-medium">
        1. Recommendations and Tips
      </p>

      <div className="mt-4 xl:mt-[21px] ml-[15px] xl:ml-[19px] text-twinworldGray-770 xl:text-twinworldGray-800 text-[14px] xl:text-[18px] leading-[24px] font-normal pr-[2px]">
        Before uploading your item, use the thumbnail guide to position your
        item's thumbnail image effectively.
      </div>

      <div className="ml-[15px] xl:ml-[19px] font-semibold xl:font-medium mt-4 xl:mt-[40px] text-[14px] xl:text-[18px]">
        Follow these steps:
      </div>

      <ul className="flex flex-col gap-1 xl:flex-none ml-[32px] mt-[16px] xl:mt-4 list-disc text-twinworldGray-770 xl:text-twinworldGray-800 leading-6 xl:leading-8 text-[14px] xl:text-[16px] font-normal">
        <li>Ensure your item is centered.</li>
        <li>Showcase your item from its best angle.</li>
        (consider the main front or perspective view.)
        <li>
          Display the entire item in the right size for optimal visibility.
        </li>
        <li>
          Display the entire item in the right size for optimal visibility.
        </li>
        <li>
          To ensure the thumbnail is displayed appropriately, the recommended
          object size is 7m x 7m x 7m.
        </li>
        <li>
          The minimum upload size for an object is 1.2m x 1.2m x 1.2m. Make sure
          to appropriately enlarge the thumbnail before uploading!
        </li>
      </ul>

      {/* 섬네일 */}
      <div className="xl:w-[819px] xl:ml-[19px] grid grid-cols-2 xl:grid-cols-4 gap-[18px] mt-[20px] px-12 xl:px-0">
        <img
          src="/images/guide/thumbnail/img_item_guide_flower.png"
          alt="guide_flower"
        />
        <img
          src="/images/guide/thumbnail/img_item_guide_jacket.png"
          alt="guide_jacket"
        />
        <img
          src="/images/guide/thumbnail/img_item_guide_car.png"
          alt="guide_car"
        />
        <img
          src="/images/guide/thumbnail/img_item_guide_taco.png"
          alt="guide_taco"
        />
      </div>

      <div className="ml-[15px] xl:ml-[19px] font-semibold xl:font-medium mt-4 xl:mt-[40px] text-[14px] xl:text-[18px] text-twinworldGray-770 xl:text-twinworldGray-800">
        Attention!
      </div>

      <div className="ml-[15px] xl:ml-[19px] font-semibold xl:font-medium mt-[10px] text-[14px] xl:text-[18px] text-twinworldGray-770 xl:text-twinworldGray-800">
        Be cautious if:
      </div>

      <ul className="flex flex-col gap-1 xl:flex-none ml-[32px] mt-[16px] xl:mt-4 list-disc text-twinworldGray-770 xl:text-twinworldGray-800 leading-6 xl:leading-8 text-[14px] xl:text-[16px] font-normal">
        <li>
          Your item is positioned in a way that cuts off, leading to an
          incomplete representation.{" "}
        </li>
        (Exception: Long items such as trains may have both sides cut off.)
        <li>The thumbnail significantly differs from the standard style.</li>
        <li>The content is unrelated to the item.</li>
        <li>
          The item image doesn't display the front or main view (e.g., side or
          back views).
        </li>
      </ul>

      {/* 섬네일 */}
      <div className="xl:w-[819px] xl:ml-[19px] grid grid-cols-2 xl:grid-cols-4 gap-[18px] mt-[20px] px-12 xl:px-0">
        <img
          src="/images/guide/thumbnail/img_item_not_guide_flower.png"
          alt="guide_not_flower"
        />
        <img
          src="/images/guide/thumbnail/img_item_not_guide_jacket.png"
          alt="guide_not_jacket"
        />
        <img
          src="/images/guide/thumbnail/img_item_not_guide_car.png"
          alt="guide_not_car"
        />
        <img
          src="/images/guide/thumbnail/img_item_not_guide_taco.png"
          alt="guide_not_taco"
        />
      </div>

      <p className="text-[16px] xl:text-[20px] font-semibold xl:font-medium mt-[36px] xl:mt-[50px]">
        2. Other Inquiries
      </p>

      <div className="mt-4 xl:mt-[21px] ml-[15px] xl:ml-[19px] text-twinworldGray-770 xl:text-twinworldGray-800 text-[14px] xl:text-[18px] leading-[24px] font-normal pr-[2px]">
        For any other questions related to items, please use the 'Support' page.
      </div>
    </div>
  </section>
);

export default ThumbnailImageGuide;
