import DividerIcon from "../../../landing/Introduce/DividerIcon";

import MobileMainMask from "./MobileMainMask";
import MobileMetaQuertBtn from "./MobileMetaQuestBtn";

export default function MobileIntroduce() {
  return (
    <section className="mt-6 px-[18px] md:px-[24px]">
      <h1 className="text-[48px] leading-[58px] font-semibold min-w-[302px]">
        Live Beyond Reality
      </h1>
      <div className="flex flex-row items-center mt-[13px]">
        <p className="w-[252px] text-[18px] leading-[25px] font-normal">
          Create, enjoy, and share <br /> a new realm beyond reality!
        </p>
        <div className="ml-auto mr-[17px]">
          <DividerIcon />
        </div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4708 14.7559V10.3526H13.2234V8.0524H10.4708V5.33325H7.87227V8.0524H5.3335V8.32411C5.33584 8.38218 5.3376 8.44492 5.3376 8.51648C5.3376 10.0503 6.56657 10.3127 7.13419 10.3526H7.87227V14.7559C7.87227 16.9124 9.85397 18.6666 12.2902 18.6666H13.3335V16.3664H12.2902C11.2874 16.3664 10.4714 15.6441 10.4714 14.7564L10.4708 14.7559Z"
            fill="#22212A"
          />
          <path
            d="M16.5998 5.33319L14.667 7.17041L16.6011 9.00629L18.5339 7.16906L16.5998 5.33319Z"
            fill="#22212A"
          />
          <circle cx="12" cy="12" r="11.25" stroke="black" strokeWidth="1.5" />
        </svg>
      </div>
      <div className="relative flex justify-center mt-[31px]">
        <MobileMainMask />
        <MobileMetaQuertBtn />
      </div>
    </section>
  );
}
