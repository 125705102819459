import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import { useEffect, useRef } from "react";

type Params = {
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<any, unknown>>;
};

export const useInfiniteScrollRef = ({ fetchNextPage }: Params) => {
  const targetRef = useRef(null);

  const handleIntersection: IntersectionObserverCallback = (entries, io) => {
    if (entries[0].isIntersecting) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0,
    });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [targetRef.current]);

  return { targetRef };
};
