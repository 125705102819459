import { useNavigate } from "react-router-dom";

import Avatar from "../common/Avatar";
import Image from "../common/Image";
import SpaceLiveUser from "../space/SpaceLiveUser";

import AudienceOverview from "./AudienceOverview";

type Props = {
  id: string;
  thumbnailSrc: string;
  avatarSrc: string;
  title: string;
  creator: string;
  creatorId: string;
  views: number;
  plays: number;
  liveUserCount: number;
  onClick: (id: string) => void;
  rounded?: string;
};

export default function MobileSpaceCard({
  id,
  thumbnailSrc,
  avatarSrc,
  title,
  creator,
  creatorId,
  views,
  plays,
  liveUserCount,
  onClick,
  rounded = "rounded-none",
}: Props) {
  const navigate = useNavigate();

  const handleClickAvatar = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/user/${creatorId}`);
  };

  return (
    <section
      className="flex flex-col w-[339px] h-[299px] cursor-pointer relative"
      onClick={() => onClick(id)}
    >
      <Image
        border
        rounded={rounded}
        src={thumbnailSrc}
        alt="space image"
        width="w-[399px]"
        height="h-[252px]"
        defaultSrc="/images/space/space_card_default.webp"
        hoverEffect={"xl:hover:border-[#2C4B99] xl:hover:border-2"}
      />

      {liveUserCount > 0 && (
        <div className="absolute top-[11px] right-[11px] first-letter:w-fit">
          <SpaceLiveUser userCount={liveUserCount} />
        </div>
      )}

      <div className="relative flex w-full mt-[12px]">
        <Avatar size="sm" src={avatarSrc} onClick={handleClickAvatar} />
        <div className="w-[309px] flex flex-col gap-[5px] pl-[12px]">
          <div className="flex items-center justify-between ">
            <h3 className="w-[146px] font-medium truncate text-[14px] leading-[17px] text-twinworldGray-700">
              {title}
            </h3>
            <AudienceOverview views={views} plays={plays} />
          </div>
          <p className="w-[146px] text-[13px] leading-[16px] truncate text-twinworldGray-700">
            {creator}
          </p>
        </div>
      </div>
    </section>
  );
}
