import { useLocation } from "react-router-dom";

import Loading from "../components/common/Loading";
import MainWrapper from "../components/common/MainWrapper";
import ItemGroupList from "../components/common/list/ItemGroupList";
import ItemDetails from "../components/item/ItemDetails";
import { useAuthContext } from "../contexts/AuthContext";
import { useItemActions } from "../domains/item/hook/useItemActions";
import { useItemListActions } from "../domains/item/hook/useItemListAction";

export const AnItem = () => {
  const { profile } = useAuthContext();
  const location = useLocation();
  const itemId = location.pathname.split("/")[2];

  const useItemAction = useItemActions();
  const { useItemDetails } = useItemAction;
  const { isLoading, isError, itemDetails, itemDetailsError } = useItemDetails(
    itemId!
  );

  const useItemListAction = useItemListActions();
  const { useItemList } = useItemListAction;

  const { isLoading: isRecommendLoading, data: recommendList } = useItemList({
    condition: "#recommend",
    keyword: "",
    take: 5,
  });

  const { isLoading: isUploadLoading, data: uploaderList } = useItemList({
    condition: "#upload-list",
    keyword: "",
    take: 5,
    uploaderId: itemDetails?.uploaderId,
  });

  if (isError) {
    if (itemDetailsError.response.data.message) {
      return <div>{itemDetailsError.response.data.message}</div>;
    }
    return <div>undefined error</div>;
  }

  if (isLoading || isRecommendLoading || isUploadLoading)
    return (
      <div className="h-screen">
        <div className="flex w-full h-full">
          <Loading />
        </div>
      </div>
    );

  return (
    <MainWrapper>
      <ItemDetails userId={profile?.userId || ""} {...itemDetails!} />
      <div className="mt-[70px] xl:mt-[125px] flex flex-col gap-[46px] xl:gap-24 w-full">
        {uploaderList && (
          <ItemGroupList
            title={`Uploader: ${itemDetails?.creator}`}
            list={uploaderList!}
            moreButtonLink={`/user/${itemDetails?.creatorId}`}
            navigateState="items"
          />
        )}
        {recommendList && (
          <ItemGroupList
            title="Popular"
            list={recommendList!}
            moreButtonLink="/items"
          />
        )}
      </div>
    </MainWrapper>
  );
};
