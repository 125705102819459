type Props = {
  number: number;
  title: React.ReactNode;
  frame: React.ReactNode;
  paragraphs: string[];
};

export default function MobileValue({
  number,
  title,
  frame,
  paragraphs,
}: Props) {
  return (
    <section
      className={`flex flex-col py-[40px] ${
        number % 2 === 0 ? "bg-white" : "bg-[#F5FAFD]"
      } px-[18px] md:px-[24px]`}
    >
      <div className="w-fit h-[34px] px-[14px] py-[10px] text-[14px] font-semibold text-twinworldPrimary-200 bg-black rounded-full">
        {`Value ${String(number).padStart(2, "0")}`}
      </div>
      <h2 className="text-[28px] font-semibold leading-[36.4px] break-words w-[260px] md:w-[394px] mt-[12px]">
        {title}
      </h2>
      <div className="mt-[22px]">{frame}</div>
      <div className="flex flex-col gap-[14px] text-[16px] font-normal text-[#2C2C2C] leading-[28.8px] mt-[24px]">
        {paragraphs.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    </section>
  );
}
