import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { useSpaceActions } from "../../domains/space/hook/useSpaceActions";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import { useInfiniteScrollRef } from "../../hooks/useInfiniteScrollRef";
import SpaceList from "../SpaceList";
import SpaceCard from "../card/SpaceCard";
import ListWrapper from "../common/ListWrapper";
import Loading from "../common/Loading";
import NoMatchingResults from "../common/NoMatchingResults";
import MobileSpaceCard from "../mobile/MobileSpaceCard";

type Props = {
  groupId: string;
  keyword: string;
};

export default function SpaceGroupsAllList({ groupId, keyword }: Props) {
  const { isDesktop } = useDeviceBreakPoint();
  const navigate = useNavigate();
  const { useInfiniteGroupsSpaceList } = useSpaceActions();

  const { isLoading, data, fetchNextPage } = useInfiniteGroupsSpaceList(
    groupId,
    24,
    0
  );

  const { targetRef } = useInfiniteScrollRef({
    fetchNextPage,
  });

  const handleClickCard = (spaceId: string) => navigate(`/spaces/${spaceId}`);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <section className="xl:min-w-[1440px] mx-auto">
      {!keyword && (
        <h3 className="hidden xl:block text-[40px] font-semibold mb-[30px]">
          {data && data?.pages[0].map((space) => space.group.name)}
        </h3>
      )}

      {keyword ? (
        <SpaceList type="all" selectedCategory="" keyword={keyword} />
      ) : (
        <ListWrapper isEmptyData={!data?.pages[0].length}>
          {data && data.pages[0].length > 0 ? (
            data?.pages.map((page, pageIndex) => (
              <Fragment key={pageIndex}>
                {page.map(({ spaces }) =>
                  spaces.map((space) => (
                    <>
                      {isDesktop ? (
                        <SpaceCard
                          key={space.spaceId}
                          id={space.spaceId}
                          title={space.spaceName}
                          thumbnailSrc={space.spaceImage}
                          creator={space.spaceCreator}
                          creatorId={space.spaceCreatorId}
                          avatarSrc={space.spaceCreatorImage}
                          onClick={() => handleClickCard(space.spaceId)}
                          views={space.views}
                          plays={space.plays}
                          liveUserCount={space.liveUserCount}
                          rounded="rounded-[20px]"
                        />
                      ) : (
                        <MobileSpaceCard
                          key={space.spaceId}
                          id={space.spaceId}
                          title={space.spaceName}
                          thumbnailSrc={space.spaceImage}
                          creator={space.spaceCreator}
                          creatorId={space.spaceCreatorId}
                          avatarSrc={space.spaceCreatorImage}
                          onClick={() => handleClickCard(space.spaceId)}
                          views={space.views}
                          plays={space.plays}
                          liveUserCount={space.liveUserCount}
                          rounded="rounded-[10px]"
                        />
                      )}
                    </>
                  ))
                )}
              </Fragment>
            ))
          ) : (
            <NoMatchingResults />
          )}
          <div ref={targetRef} />
        </ListWrapper>
      )}
    </section>
  );
}
