import { useEffect } from "react";

type Props = {
  isDropdownMenuActive: boolean;
  isMobileGnbMenuActive: boolean;
};

export default function HeaderDim({
  isDropdownMenuActive = false,
  isMobileGnbMenuActive = false,
}: Props) {
  // Dim이 활성화 되었을 때 모바일 touchmove 막기
  useEffect(() => {
    const handleTouchMove = (event: TouchEvent) => {
      if (isDropdownMenuActive || isMobileGnbMenuActive) {
        event.preventDefault();
      }
    };

    window.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });
    return () => window.removeEventListener("touchmove", handleTouchMove);
  }, [isDropdownMenuActive, isMobileGnbMenuActive]);

  return (
    <section>
      <div
        className={`${
          (isDropdownMenuActive || isMobileGnbMenuActive) &&
          "fixed top-[89px] xl:top-0 left-0 right-0 w-full xl:min-w-[1440px] h-full bg-black/70 z-50"
        }`}
      />

      {/* active state랑 useOutsideDetectRef hook 충돌을 막기위한 처리 */}
      <div
        className={`${
          (isDropdownMenuActive || isMobileGnbMenuActive) &&
          "fixed top-0 left-0 right-0 w-full xl:min-w-[1440px] h-[89px] z-50"
        }`}
      />
    </section>
  );
}
