import { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

import { useItemActions } from "../../domains/item/hook/useItemActions";
import { useMyBoxActions } from "../../domains/item/hook/useMyBoxActions";
import { useMyItemActions } from "../../domains/item/hook/useMyItemActions";
import { getByteSize } from "../../utils/lib";
import Viewer3D from "../common/Viewer3D";
import useModals from "../hooks/useModals";
import ItemEditDeleteModal from "../modal/ItemEditDeleteModal";
import ItemEditUpdateModal from "../modal/ItemEditUpdateModal";
import ItemCategorySelector from "../selector/ItemCategorySelector";

import { ItemDetailsInfo } from "./type";

type Props = {
  itemDetail: ItemDetailsInfo;
};

const TITLE_MAX_BYTE = 30;

const characterCheck = (word: string) =>
  word.replace(/[^a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣\s]/g, "");

export default function ItemEditSection({ itemDetail }: Props) {
  const navigate = useNavigate();

  const { itemId, name, itemUrl, categoryName, categoryId, thumbnailUrl } =
    itemDetail;

  const { itemCategoryList } = useItemActions();
  const { updateMyItemInfo, deleteMyItem } = useMyItemActions();
  const { deleteFromMyBox } = useMyBoxActions();
  const { openModal, closeModal } = useModals();

  const [itemState, setItemState] = useState({
    getTitle: name,
    category: {
      idx: categoryId,
      name: categoryName,
      unavailable: true,
    },
  });
  const { getTitle, category } = itemState;

  const handleChangeItem = (
    key: string,
    value: string | File | object | boolean
  ) => {
    setItemState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const word = characterCheck(e.target.value);

    if (getByteSize(word) <= TITLE_MAX_BYTE) {
      handleChangeItem("getTitle", word);
    }
  };

  const handleUpdateItemInfo = async () => {
    await updateMyItemInfo({
      itemId,
      name: getTitle,
      categoryId: category.idx,
      isPublic: true,
    }).then(() => {
      closeModal(ItemEditUpdateModal);
      navigate(`/items/${itemDetail.itemId}`);
    });
  };

  const handleOpenItemUpdateModal = () => {
    openModal(ItemEditUpdateModal, {
      itemThumbnail: thumbnailUrl,
      itemName: itemState.getTitle,
      handleUpdate: handleUpdateItemInfo,
    });
  };

  const handleDeleteMyItem = async () => {
    await Promise.all([deleteMyItem(itemId), deleteFromMyBox(itemId)])
      .then((result) => {
        closeModal(ItemEditDeleteModal);
        navigate("/myprofile", { state: { menu: "items" } });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpenItemDeleteModal = () => {
    openModal(ItemEditDeleteModal, {
      itemThumbnail: thumbnailUrl,
      itemName: name,
      handleDelete: handleDeleteMyItem,
    });
  };

  return (
    <div className="flex flex-col items-center justfy-center">
      <Viewer3D renderFileUrl={itemUrl} size={"w-[522px] h-[388px]"} rounded />
      <div className="w-[530px] flex flex-col gap-4 mt-9">
        <div className="w-full py-4 border rounded-full px-7 border-twinworldGray-350">
          <input
            className="w-[390px]"
            type="text"
            placeholder="Title"
            defaultValue={itemDetail.name}
            onChange={onChangeInput}
            value={itemState.getTitle}
          />
          <span className="text-[13px] leading-none text-twinworldGray-600">
            {getByteSize(itemState.getTitle)} / 30 byte
          </span>
        </div>
        <div className="w-full">
          {itemCategoryList && (
            <ItemCategorySelector
              selectedCategory={itemState.category}
              categoryList={itemCategoryList}
              setSelectedCategory={handleChangeItem}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-6 items-center mt-[81px]">
        <button
          className="px-[100px] py-[21px] text-black bg-twinworldPrimary-200 font-semibold text-[16px] rounded-full border-2 border-black hover:text-twinworldPrimary-200 hover:bg-black"
          onClick={handleOpenItemUpdateModal}
        >
          Save & Upload
        </button>
        <button
          className="text-[16px] text-twinworld-700 underline mt-6"
          onClick={handleOpenItemDeleteModal}
        >
          Delete my item
        </button>
      </div>
    </div>
  );
}
