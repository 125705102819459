type Props = {
  isHovered?: boolean;
};

export default function UpArrow({ isHovered }: Props) {
  return (
    <svg
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13C1 13 9.78049 1 10.2195 1C10.6585 1 19.5 13 19.5 13"
        stroke={`${isHovered ? "white" : "black"}`}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
