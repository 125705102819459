import { AvatarCreatorViewer } from "@readyplayerme/rpm-react-sdk";
import { useState } from "react";
import FadeLoader from "react-spinners/FadeLoader";

import { useAuthContext } from "../../contexts/AuthContext";
import { useProfileActions } from "../../domains/user/hook/useProfileActions";
import ProfileSaveButton from "../profile/ProfileSaveButton";
import ButtonToast from "../toast/ButtonToast";

export default function ReadyPlayMeViewer() {
  const { profile } = useAuthContext();
  const { updateUserProfile } = useProfileActions();

  const [url, setUrl] = useState(profile?.avatarUrl ?? "");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleSaveAvatar = () =>
    updateUserProfile({
      avatarUrl: url,
    }).then(() => {
      setIsSuccess(true);
      setTimeout(() => setIsSuccess(false), 1000);
    });

  const handleAvatarExported = (newUrl: string) => setUrl(newUrl);

  const loadingNode = (
    <div className="absolute z-10 flex items-center justify-center w-full h-full bg-twinworldGray-150">
      <FadeLoader
        color={"#2D2D2D"}
        width={9}
        height={28}
        radius={"10px"}
        margin={20}
      />
    </div>
  );

  return (
    <section className="relative flex flex-col items-center w-full mt-[42px] xl:mt-0 px-[18px] md:px-[24px] xl:px-0">
      <div className="w-full h-[510px] md:h-[671px] lg:h-[883px] xl:h-[994px] rounded-[10px] xl:rounded-[15px] overflow-hidden">
        <AvatarCreatorViewer
          editorConfig={{
            clearCache: true,
          }}
          viewerConfig={{
            halfBody: false,
          }}
          subdomain="twinworld-ud81cb"
          onAvatarExported={handleAvatarExported}
          loadingNode={loadingNode}
          onAvatarLoaded={() => setIsDisabled(false)}
        />
      </div>

      <div className="relative flex items-center justify-center mt-[30px] xl:mt-20">
        <ProfileSaveButton isDisabled={isDisabled} onClick={handleSaveAvatar}>
          Save Avatar
        </ProfileSaveButton>

        <ButtonToast idDisplay={isSuccess} message={"Saved successfully"} />
      </div>
    </section>
  );
}
