type Props = {
  children: React.ReactNode | React.ReactNode[];
  isEmptyData?: boolean;
};

export default function ItemListWrapper({
  children,
  isEmptyData = false,
}: Props) {
  const classes = () => {
    if (isEmptyData) {
      return `flex items-center h-auto`;
    }

    return `grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 
    gap-x-[11px] md:gap-x-[24px] lg:gap-x-[37px] xl:gap-x-[50px] gap-y-[34px] md:gap-y-[69px] lg:gap-y-[104px] xl:gap-y-[140px] 
    w-[339px] md:w-[800px] lg:w-[1073px] xl:w-[1440px]  h-auto mx-auto`;
  };

  return <div className={`${classes()}`}>{children}</div>;
}
