import { UserInfo } from "../../domains/user/interface";
import ItemUploadButton from "../button/ItemUploadButton";
import Avatar from "../common/Avatar";
import PersonIcon from "../common/icons/PersonIcon";
import GnbDropdownMenu from "../menu/GnbDropdownMenu";

type Props = {
  profile: UserInfo | undefined;
  isDropdownMenuActive: boolean;
  handleDropdownMenuToggle: () => void;
};

export default function UserMenu({
  profile,
  isDropdownMenuActive,
  handleDropdownMenuToggle,
}: Props) {
  return (
    <div className="flex items-center">
      <ItemUploadButton profile={profile} />

      <div
        className="relative ml-5 cursor-pointer"
        onClick={handleDropdownMenuToggle}
      >
        {profile ? (
          <Avatar size="md" src={profile.avatarImageUrl} />
        ) : (
          <PersonIcon />
        )}

        {isDropdownMenuActive && (
          <GnbDropdownMenu
            profile={profile}
            isActive={isDropdownMenuActive}
            setIsActive={handleDropdownMenuToggle}
          />
        )}
      </div>
    </div>
  );
}
