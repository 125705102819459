import { Link } from "react-router-dom";

import { UserInfo } from "../../../domains/user/interface";
import Avatar from "../../common/Avatar";
import PersonIcon from "../../common/icons/PersonIcon";
import MobileGnbDropdownMenu from "../MobileGnbDropdownMenu";
import MobileGnbMenu from "../MobileGnbMenu";
import MobileMenuIcon from "../icons/MobileMenuIcon";

type Props = {
  profile: UserInfo | undefined;
  isDropdownMenuActive: boolean;
  isMobileGnbMenuActive: boolean;
  handleDropdownMenuToggle: () => void;
  handleMobileGnbMenuToggle: () => void;
};

export default function MobileUserMenu({
  profile,
  isDropdownMenuActive,
  isMobileGnbMenuActive,
  handleDropdownMenuToggle,
  handleMobileGnbMenuToggle,
}: Props) {
  return (
    <div className="flex items-center">
      <button type="button" className="relative cursor-pointer">
        {profile ? (
          <div onClick={handleDropdownMenuToggle}>
            <Avatar size="md" src={profile.avatarImageUrl} />
          </div>
        ) : (
          <Link to="/sign-in">
            <PersonIcon />
          </Link>
        )}
      </button>
      <button
        type="button"
        className="ml-5"
        onClick={handleMobileGnbMenuToggle}
      >
        <MobileMenuIcon />
      </button>

      {isDropdownMenuActive && (
        <MobileGnbDropdownMenu
          profile={profile}
          isActive={isDropdownMenuActive}
          setIsActive={handleDropdownMenuToggle}
        />
      )}

      {isMobileGnbMenuActive && (
        <MobileGnbMenu
          isActive={isMobileGnbMenuActive}
          setIsActive={handleMobileGnbMenuToggle}
        />
      )}
    </div>
  );
}
