import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import MainWrapper from "../components/common/MainWrapper";
import QuestionMarkIcon from "../components/common/icons/QuestionMarkIcon";
import FileDragDrop from "../components/fileDragDown";
import ItemUploadErrorModal from "../components/modal/ItemUploadErrorModal";
import ItemUploadSuccessModal from "../components/modal/ItemUploadSuccessModal";
import ItemCategorySelector from "../components/selector/ItemCategorySelector";
import UploadModelViewer from "../components/viewer/UploadModelViewer";
import { useItemActions } from "../domains/item/hook/useItemActions";
import { useItemUploadActions } from "../domains/item/hook/useItemUploadActions";
import { ItemFile, uploadGLBItemInfo } from "../domains/item/interface";
import use3DModelScreenshot from "../hooks/use3DModelScreenshot";

type Item = {
  uploadItemFile: ItemFile[];
  uploadPreviewGifFile?: File;
  uploadPreviewSpriteFile?: File;
  title: string;
  category: {
    idx: string;
    name: string;
    unavailable?: boolean;
  };
  previewThumbnail: string;
};

const DEFAULT_ITEM: Item = {
  uploadItemFile: [],
  title: "",
  category: {
    idx: "",
    name: "Choose a category",
    unavailable: true,
  },
  previewThumbnail: "",
};

const MAX_TITLE_LENGTH = 30;

export default function ItemUpload() {
  const navigate = useNavigate();
  const { itemCategoryList } = useItemActions();
  const { fiberCanvasCapture } = use3DModelScreenshot();

  const { uploadItem } = useItemUploadActions();

  const [isDisabledPublishBtn, setIsDisabledPublishBtn] = useState(true);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [isUploadError, setIsUploadError] = useState(false);
  const [isCaptureFinished, setIsCaptureFinished] = useState(false);
  const [uploadItemId, setUploadItemId] = useState<string>("");

  const [item, setItem] = useState<Item>(DEFAULT_ITEM);
  const {
    title,
    uploadItemFile,
    uploadPreviewGifFile,
    uploadPreviewSpriteFile,
    previewThumbnail,
    category,
  } = item;

  const isEmpty: boolean =
    isCaptureFinished &&
    title !== "" &&
    category.idx !== "" &&
    uploadItemFile.length > 0;

  const handleChangeItem = (
    key: string,
    value: string | File | object | boolean
  ) => {
    setItem((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= MAX_TITLE_LENGTH) {
      handleChangeItem("title", e.target.value);
    }
  };

  const handleUploadItem = async () => {
    if (uploadItemFile[0].extension !== "glb") {
      alert("Please upload only GLB files.");
      return;
    }

    setIsDisabledPublishBtn(true);

    const { dataURL, thumbnailFile } = await fiberCanvasCapture(
      "uploadModelViewer"
    );

    handleChangeItem("previewThumbnail", dataURL);

    const thumbnailSprite = uploadPreviewSpriteFile!;
    const thumbnailGif = uploadPreviewGifFile!;

    const data: uploadGLBItemInfo = {
      name: title,
      categoryId: category.idx,
      fileType: "model",
      type: ["public"],
      thumbnail: thumbnailFile,
      thumbnailSprite,
      thumbnailGif,
      media: uploadItemFile[0].file,
    };

    try {
      const response = await uploadItem(data);
      if (response) {
        setIsDisabledPublishBtn(false);
        setUploadItemId(response);
        setIsUploadSuccess(true);
      }
    } catch (error: any) {
      console.error(error);
      setIsCaptureFinished(false);
      setIsDisabledPublishBtn(false);
      setIsUploadError(true);
    }
  };

  const closeUploadSuccessModal = () => {
    setIsUploadSuccess(false);
    setItem(DEFAULT_ITEM);
  };

  const handleCheckUploadItem = () => navigate(`/items/${uploadItemId}`);

  const handleCloseErrorModal = () => {
    setIsUploadError(false);
    setItem(DEFAULT_ITEM);
  };

  useEffect(() => {
    setIsDisabledPublishBtn(!isEmpty);
  }, [isEmpty]);

  useEffect(() => {
    if (!uploadItemFile.length) return;

    setItem((prev) => ({
      ...prev,
      title: "",
      category: {
        idx: "",
        name: "Choose a category",
        unavailable: true,
      },
    }));
  }, [uploadItemFile]);

  return (
    <MainWrapper>
      <div className="flex flex-col w-full items-center gap-[84px]">
        <div className="flex gap-[102px]">
          <div className="flex flex-col justify-center gap-4">
            <div className="flex justify-center items-center w-[588px] h-[438px] bg-twinworldGray-150 rounded-3xl border border-twinworldGray-300 overflow-hidden">
              <UploadModelViewer
                renderFileUrl={
                  uploadItemFile.length === 0 ? "" : uploadItemFile[0].preview!
                }
                captureFinished={() => setIsCaptureFinished(true)}
                setPreviewImageFile={handleChangeItem}
              />
            </div>
            <a
              className="flex justify-center text-twinworldGray-650 text-[13px] leading-none cursor-pointer"
              href={`${process.env.PUBLIC_URL}/guide#thumbnail-guide`}
              target="_blank"
              rel="noreferrer"
            >
              Thumbnail image guide
              <div className="ml-[6px]">
                <QuestionMarkIcon />
              </div>
            </a>
          </div>
          <div className="flex flex-col">
            <div className="w-[530px] h-[262px]">
              <FileDragDrop
                uploadFile={uploadItemFile}
                handleSetUploadFile={handleChangeItem}
              />
            </div>
            <div className="flex items-center justify-between w-[530px] py-4 border rounded-full px-7 border-twinworldGray-350 mt-11">
              <input
                className="w-[430px]"
                type="text"
                placeholder="Title"
                value={title}
                onChange={handleChangeTitle}
              />
              <span className="text-[13px] leading-none text-twinworldGray-600">
                {title.length}/30
              </span>
            </div>
            <div className="mt-4">
              {itemCategoryList && (
                <ItemCategorySelector
                  selectedCategory={category}
                  categoryList={itemCategoryList}
                  setSelectedCategory={handleChangeItem}
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-start">
          <button
            className={`border-2 rounded-full w-80 h-14 font-semibold ${
              isDisabledPublishBtn
                ? "border-twinworldGray-500 bg-twinworldSecondary-200 text-twinworldGray-500 cursor-not-allowed"
                : "cursor-pointer border-black bg-twinworldPrimary-200 hover:text-twinworldPrimary-200 hover:bg-black"
            }`}
            type="button"
            onClick={handleUploadItem}
            disabled={isDisabledPublishBtn}
          >
            Save & Upload
          </button>
        </div>

        {isUploadSuccess && (
          <ItemUploadSuccessModal
            itemThumbnail={previewThumbnail}
            itemName={title}
            closeModal={closeUploadSuccessModal}
            handleCheckUploadItem={handleCheckUploadItem}
          />
        )}
        {isUploadError && (
          <ItemUploadErrorModal closeModal={handleCloseErrorModal} />
        )}
      </div>
    </MainWrapper>
  );
}
