export type CoinsCategory = "지급" | "차감";

export type CoinsUsageHistory = {
  id: string;
  category: string;
  description: string;
  coinsId: string;
  type: CoinsCategory;
  value: number;
  createdAt: string;
};

export type ResponseData = {
  id: string;
  userId: string;
  totalEarnCoins: number;
  totalUsedCoins: number;
  histories: CoinsUsageHistory[] | [];
  updatedAt: string;
  createAt: string;
};

export type CoinsSummary = {
  currentCoin: string;
  totalEarnCoins: string;
  totalUsedCoins: string;
};

class CoinsHistory {
  usageHistory: CoinsUsageHistory[];

  coinsSummary: CoinsSummary;

  constructor(data: ResponseData | undefined) {
    this.coinsSummary = {
      currentCoin: data ? `${data.totalEarnCoins - data.totalUsedCoins}` : "0",
      totalEarnCoins: data ? `${data.totalEarnCoins}` : "-",
      totalUsedCoins: data ? `${data.totalUsedCoins}` : "-",
    };
    this.usageHistory = data
      ? data.histories.map((history) => ({
          ...history,
          createdAt: this.formatDate(history.createdAt),
        }))
      : [];
  }

  formatDate(date: string) {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");

    return `${year}.${month}.${day} ${hours}:${minutes}`;
  }
}

export default CoinsHistory;
