import { cva } from "class-variance-authority";

import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import { classNameMerge } from "../../utils/tailwindClassMerge";
import OptimizingFailIcon from "../common/icons/OptimizingFailIcon";
import OptimizingIcon from "../common/icons/OptimizingIcon";
import { ConversionStatus } from "../item/type";

type Props = {
  type: "card" | "details";
  conversionStatus: ConversionStatus;
  width?: string;
  height?: string;
  rounded?: string;
};

const TITLE_VARIANTS = cva("font-normal text-twinworldGray-700", {
  variants: {
    type: {
      card: "text-sm leading-tight mt-2",
      details: "text-base leading-none mt-5",
    },
  },
  defaultVariants: {
    type: "card",
  },
});

export default function ConversionStatusThumbnail({
  type,
  conversionStatus,
  width,
  height,
  rounded = "rounded-[10px]",
}: Props) {
  const { isMobile } = useDeviceBreakPoint();

  const VARIANTS = cva(
    "flex flex-col justify-center gap-2 items-center border border-twinworldGray-300",
    {
      variants: {
        type: {
          card: `${
            isMobile ? rounded : "rounded-[21px]"
          } md:w-[248px] md:h-[248px] `,
          details: `w-full min-w-[339px] xl:w-[700px] h-[258px] md:h-[456px] lg:h-[616px] xl:h-[522px] ${rounded}`,
        },
      },
      defaultVariants: {
        type: "card",
      },
    }
  );

  const classes = classNameMerge(VARIANTS({ type }));
  const titleClasses = classNameMerge(TITLE_VARIANTS({ type }));
  const conversionStatusTitle = {
    converting: "Model optimizing...",
    failed: "Optimizing fail",
    success: "",
  };

  return (
    <section className={`${classes} ${width} ${height}`}>
      {conversionStatus === "failed" && <OptimizingFailIcon type={type} />}
      {conversionStatus === "converting" && <OptimizingIcon type={type} />}

      <p className={`${titleClasses} `}>
        {conversionStatusTitle[conversionStatus]}
      </p>
    </section>
  );
}
