import { WebIO } from "@gltf-transform/core";
import { KHRONOS_EXTENSIONS } from "@gltf-transform/extensions";
import { metalRough } from "@gltf-transform/functions";
import { LoadingManager, REVISION } from "three";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader";

const manager = new LoadingManager();

const getGltf = (glb: Uint8Array, gl: THREE.WebGLRenderer): Promise<GLTF> => new Promise((resolve, reject) => {
    const loader = new GLTFLoader();
    loader.manager = manager;
    const THREE_PATH = `https://unpkg.com/three@0.${REVISION}.x`;
    const ktx2Loader = new KTX2Loader(manager).setTranscoderPath(
      `${THREE_PATH}/examples/jsm/libs/basis/`
    );
    loader.setKTX2Loader(ktx2Loader.detectSupport(gl));

    loader.parse(
      glb.buffer,
      "",
      (gltf) => {
        resolve(gltf);
      },
      (error) => {
        reject(error);
      }
    );
  });

/**
 * glb, gltf 파일에서 three.js에서 지원하지 않는
 * KHR_materials_pbrSpecularGlossiness를 사용할 경우
 * metal/rough PBR materials로 변경 후 gltf를 반환하는 함수
 * @param  {string} url GLTF 모델 경로
 * @returns Promise<GLTF> 반환
 */
export const loadModelWithTransform = async (
  url: string,
  gl: THREE.WebGLRenderer
): Promise<GLTF> => new Promise(async (resolve, reject) => {
    try {
      // Load model in glTF Transform.
      const io = new WebIO().registerExtensions(KHRONOS_EXTENSIONS);
      const document = await io.read(url);
      const extensionsUsed = document.getRoot().listExtensionsUsed();

      const hasKHRMaterialPBRSpecularGlossiness = extensionsUsed.some(
        (extension) =>
          extension.extensionName === "KHR_materials_pbrSpecularGlossiness"
      );

      if (hasKHRMaterialPBRSpecularGlossiness) {
        console.warn(
          "Requires spec/gloss materials (KHR_materials_pbrSpecularGlossiness), which this viewer cannot display. Materials will be converted to metal/rough."
        );

        // Convert materials.
        await document.transform(metalRough());
      }

      // Write back to GLB.
      const binary = await io.writeBinary(document);
      const gltf = await getGltf(binary, gl);
      resolve(gltf);
    } catch (error) {
      reject(error);
    }
  });
