type Props = {
  onClick?: () => void;
};

export default function NextButton({ onClick }: Props) {
  return (
    <button
      aria-label="space_carousel_next_button"
      type="button"
      className="absolute right-0 top-[145px]"
      onClick={onClick}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.500006 20C0.500007 9.23044 9.23045 0.499993 20 0.499994C30.7696 0.499995 39.5 9.23044 39.5 20C39.5 30.7696 30.7696 39.5 20 39.5C9.23045 39.5 0.500005 30.7695 0.500006 20Z"
          fill="black"
          stroke="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.9081 14.4026C20.5172 14.0125 20.5172 13.38 20.9081 12.9899C21.2991 12.5997 21.9329 12.5997 22.3238 12.9899L28.5207 19.1742C28.5347 19.1878 28.5484 19.2019 28.5616 19.2163C28.9228 19.6082 28.913 20.2184 28.5322 20.5984C28.5272 20.6034 28.5222 20.6083 28.5172 20.6131C28.5107 20.6193 28.5041 20.6255 28.4975 20.6315L22.3226 26.7939C21.9316 27.184 21.2978 27.184 20.9069 26.7939C20.516 26.4038 20.516 25.7713 20.9069 25.3811L25.4007 20.8965L12.3368 20.9209C11.7845 20.9219 11.336 20.475 11.335 19.9227C11.3339 19.3704 11.7808 18.9219 12.3331 18.9209L25.4111 18.8965L20.9081 14.4026Z"
          fill="white"
        />
      </svg>
    </button>
  );
}
