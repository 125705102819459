import { GoogleOAuthProvider } from "@react-oauth/google";
import { useNavigate, useLocation } from "react-router-dom";

import EmailIcon from "../components/common/icons/EmailIcon";
import OculusIcon from "../components/common/icons/OculusIcon";
import MobileGoogleSignInButton from "../components/mobile/MobileGoogleSignInButton";
import MobileSignInButton from "../components/mobile/MobileSignInButton";

type LocationState = {
  redirect: string;
};

export default function SignInPage() {
  const win: Window = window;
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as LocationState;

  const handleClickOculusSignIn = () => {
    win.location = `https://auth.oculus.com/sso/?redirect_uri=${process.env.REACT_APP_WEB_URL}/sign-in/oculus&organization_id=140381008924433`;
  };

  return (
    <section className="flex flex-col items-center w-full mx-auto border-t border-twinworldGray-200">
      <h2 className="text-[20px] font-semibold leading-[25px] px-[108.5px] text-center mt-[64px]">
        Welcome to TwinWorld!
      </h2>
      <section className="flex flex-col mt-10 gap-4 px-[18px]">
        <MobileSignInButton
          icon={<OculusIcon />}
          label="Oculus"
          onClick={() => handleClickOculusSignIn()}
        />
        <GoogleOAuthProvider clientId="952391184400-iuu400ksq5pq118cmdt90b9clvgsm970.apps.googleusercontent.com">
          <MobileGoogleSignInButton />
        </GoogleOAuthProvider>
        <MobileSignInButton
          icon={<EmailIcon />}
          label="Email"
          onClick={() =>
            navigate("/email-sign-in", {
              state: {
                ...locationState,
              },
            })
          }
        />
      </section>
      <p className="my-4">or</p>
      <button
        className="border-2 rounded-full w-[339px] py-[19px] font-semibold border-twinworldGray-300 hover:transition-background-color hover:duration-300 hover:text-twinworldPrimary-200 hover:bg-black"
        type="button"
        onClick={() => navigate("/sign-up")}
      >
        Create New Account
      </button>
    </section>
  );
}
