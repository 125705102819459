import { useAuthContext } from "../../contexts/AuthContext";
import { useSpaceActions } from "../../domains/space/hook/useSpaceActions";
import Loading from "../common/Loading";

import SpaceDescription from "./SpaceDescription";
import SpaceLiveUser from "./SpaceLiveUser";

type Props = {
  spaceId: string;
};

export const SpaceDetail = ({ spaceId }: Props) => {
  const { useSpaceDetails, handleBookmark } = useSpaceActions();
  const { isLoading, isError, spaceDetails, spaceDetailsError } =
    useSpaceDetails(spaceId);

  const { isSignIn } = useAuthContext();

  const handleClickBookmark = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleBookmark(spaceId);
  };

  if (isError) {
    if (spaceDetailsError.response.data.message) {
      return <div>{spaceDetailsError.response.data.message}</div>;
    }
    return <div>undefined error</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  const { spaceDetailImage } = spaceDetails!;
  spaceDetails!.spaceId = spaceId;

  const onError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = "/images/space/img_detail_default.webp";
  };

  return (
    <div className="flex gap-20">
      <div className="flex">
        <div className="relative flex">
          <img
            alt="spaceImage"
            className="object-cover w-[700px] h-[522px] rounded-[25px] border border-twinworldGray-300"
            src={spaceDetailImage}
            onError={onError}
          />

          <div className="absolute top-[10px] right-[17px] flex flex-row gap-[17px]">
            {spaceDetails!.liveUserCount > 0 && (
              <SpaceLiveUser userCount={spaceDetails!.liveUserCount} />
            )}
            <div className="rounded-full border border-black text-semibold bg-white px-4 py-2 text-[16px]">
              {spaceDetails!.isPublic ? "Public" : "Private"}
            </div>
          </div>
        </div>

        {isSignIn && (
          <div className="absolute top-0 right-0 flex items-center w-24 h-24 mt-15 mr-15 rounded-8 bg-bgWhite/20">
            <button onClick={handleClickBookmark}></button>
          </div>
        )}
      </div>
      <div>
        <SpaceDescription {...spaceDetails!} />
      </div>
    </div>
  );
};
