export default function RightArrowIcon() {
  return (
    <div className="flex items-center justify-center bg-white rounded-full w-[76px] h-[76px]">
      <svg
        width="29"
        height="21"
        viewBox="0 0 29 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6113 1.70537C17.2204 1.31525 17.2204 0.682726 17.6113 0.292599C18.0023 -0.0975288 18.6361 -0.0975287 19.027 0.292599L28.3954 9.64204C28.7864 10.0322 28.7864 10.6647 28.3954 11.0548C28.3798 11.0704 28.3638 11.0853 28.3475 11.0997L19.0244 20.4038C18.6335 20.7939 17.9997 20.7939 17.6088 20.4038C17.2179 20.0137 17.2179 19.3812 17.6088 18.991L25.2674 11.348L1.49999 11.3479C0.947709 11.3479 0.499996 10.9002 0.499998 10.3479C0.5 9.79561 0.947717 9.34789 1.5 9.3479L25.2695 9.34799L17.6113 1.70537Z"
          fill="#223C65"
        />
      </svg>
    </div>
  );
}
