import { useParams } from "react-router-dom";

import Loading from "../components/common/Loading";
import MainWrapper from "../components/common/MainWrapper";
import ItemEditSection from "../components/item/ItemEditSection";
import { useItemActions } from "../domains/item/hook/useItemActions";

const ItemEdit = () => {
  const { itemId } = useParams();
  const useItemAction = useItemActions();
  const { useItemDetails } = useItemAction;
  const { isLoading, itemDetails } = useItemDetails(itemId!);

  if (isLoading)
    return (
      <div className="h-screen">
        <div className="flex w-full h-full">
          <Loading />
        </div>
      </div>
    );

  return itemDetails ? (
    <MainWrapper>
      <ItemEditSection itemDetail={itemDetails} />
    </MainWrapper>
  ) : (
    <div>No match Items</div>
  );
};

export default ItemEdit;
