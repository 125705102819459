import ScrollToTopButton from "../components/common/Button/ScrollToTopButton";
import MainWrapper from "../components/common/MainWrapper";
import Items from "../components/itemlist";

const categoryList = [
  {
    idx: "relevance",
    name: "Relevance",
  },
  {
    idx: "popular",
    name: "Popular",
  },
  {
    idx: "new-list",
    name: "Recent",
  },
];

const ItemsPage = () => (
  <MainWrapper>
    <Items categories={categoryList} />
    <ScrollToTopButton />
  </MainWrapper>
);

export default ItemsPage;
