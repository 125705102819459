import { useNavigate } from "react-router-dom";

import { useSpaceActions } from "../../../domains/space/hook/useSpaceActions";
import { SpaceType } from "../../../domains/space/interface";
import SpaceCard from "../../card/SpaceCard";

export default function MainSpace() {
  const navigate = useNavigate();

  const { useInfiniteSearchSpaceList } = useSpaceActions();
  const { data: spaceData } = useInfiniteSearchSpaceList(
    "trending" as SpaceType,
    "",
    8,
    0
  );

  const handleClickCard = (spaceId: string) => navigate(`/spaces/${spaceId}`);

  return (
    <section className="flex flex-col items-center">
      <h3 className="mt-[78px] xl:mt-[65px] mb-[24px] xl:mb-[52px] text-[28px] xl:text-[65px] font-semibold leading-tight w-full text-left xl:text-center px-[18px] md:px-[24px] xl:px-0">
        Twinworld Spaces
      </h3>
      {spaceData && spaceData.pages[0].length > 0 && (
        <div className="w-full overflow-x-auto overflow-y-hidden scrollbar-hidden px-[18px] md:px-[24px] xl:px-0">
          <ul className="grid grid-cols-4 gap-[8px] xl:gap-[20px] gap-y-[24px] xl:gap-y-[66px] mt-0 xl:mt-[30px] mx-auto max-w-[1440px] w-[864px] xl:w-full">
            {spaceData.pages[0].map((space) => (
              <li key={space.spaceId}>
                <SpaceCard
                  id={space.spaceId}
                  thumbnailSrc={space.spaceImage}
                  avatarSrc={space.spaceCreatorImage}
                  title={space.spaceName}
                  creator={space.spaceCreator}
                  creatorId={space.spaceCreatorId}
                  views={space.views}
                  plays={space.plays}
                  onClick={() => handleClickCard(space.spaceId)}
                  liveUserCount={space.liveUserCount}
                  rounded="rounded-[20px]"
                />
              </li>
            ))}
          </ul>
        </div>
      )}

      <button
        type="button"
        className="px-[32px] xl:px-24 py-[10px] mt-[29px] xl:mt-[76px] border-[1.5px] xl:border-2 border-black rounded-full font-semibold bg-twinworldPrimary-200 hover:bg-black hover:text-twinworldPrimary-200"
        onClick={() => navigate("/spaces?type=trending")}
      >
        more
      </button>
    </section>
  );
}
