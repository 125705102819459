import { cva } from "class-variance-authority";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import { classNameMerge } from "../../utils/tailwindClassMerge";

type Props = {
  size: "sm" | "md" | "lg" | "xl" | "2xl";
  src?: string;
  onClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
};

const AVATAR_VARIANTS = cva("rounded-full", {
  variants: {
    size: {
      sm: "w-[25px] h-[25px] xl:w-[40px] xl:h-[40px]",
      md: "w-[36px] h-[36px] xl:w-[43px] xl:h-[43px]",
      lg: "w-[40px] h-[40px] xl:w-[50px] xl:h-[50px]",
      xl: "w-[74px] h-[74px] xl:w-[90px] xl:h-[90px]",
      "2xl": "w-[100px] h-[100px] xl:w-[150px] xl:h-[150px] ",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

const IMAGE_SIZE = cva("", {
  variants: {
    size: {
      sm: 80,
      md: 86,
      lg: 100,
      xl: 180,
      "2xl": 300,
    },
  },
  defaultVariants: {
    size: "md",
  },
});

const MOBILE_IMAGE_SIZE = cva("", {
  variants: {
    size: {
      sm: 60,
      md: 72,
      lg: 80,
      xl: 144,
      "2xl": 200,
    },
  },
  defaultVariants: {
    size: "md",
  },
});

export default function Avatar({ src, size = "md", onClick }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const { isDesktop } = useDeviceBreakPoint();

  const handleImgLoaded = () => setIsLoading(false);

  const handleImgError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = "/images/avatar/default_avatar.webp";
  };

  const imgClasses = classNameMerge(AVATAR_VARIANTS({ size }));

  const getImageSize = (size: "sm" | "md" | "lg" | "xl" | "2xl") => {
    if (isDesktop) {
      return IMAGE_SIZE({ size });
    }
    return MOBILE_IMAGE_SIZE({ size });
  };

  return (
    <section className="relative flex justify-center">
      {isLoading && (
        <div className={`${imgClasses} absolute z-10`}>
          <Skeleton circle height="100%" style={{ lineHeight: "inherit" }} />
        </div>
      )}

      <img
        src={
          src
            ? `${src}?size=${getImageSize(size)}`
            : "/images/avatar/default_avatar.webp"
        }
        alt="default avatar"
        className={`${imgClasses} ${
          src ? "border border-twinworldGray-300" : ""
        }`}
        onLoad={handleImgLoaded}
        onError={handleImgError}
        onClick={onClick}
      />
    </section>
  );
}
