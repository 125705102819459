import { cva } from "class-variance-authority";

import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import { classNameMerge } from "../../utils/tailwindClassMerge";

type Props = {
  isDisabled?: boolean;
  onClick: () => void;
  className?: string;
  children: React.ReactNode;
};

const MOBILE_VARIANTS = cva(
  "text-[16px] py-[14px] px-[78px] font-medium rounded-full first-letter transition ease-in-out",
  {
    variants: {
      isDisabled: {
        true: "border-[2px] border-twinworldGray-500 bg-twinworldSecondary-200 text-twinworldGray-500 cursor-not-allowed",
        false: "bg-black text-white",
      },
    },
    defaultVariants: {
      isDisabled: false,
    },
  }
);

const DESKTOP_VARIANTS = cva(
  "text-[16px] py-[21px] px-[100px] border-[2px] font-semibold rounded-full first-letter transition ease-in-out",
  {
    variants: {
      isDisabled: {
        true: "border-twinworldGray-500 bg-twinworldSecondary-200 text-twinworldGray-500 cursor-not-allowed",
        false:
          "border-black cursor-pointer bg-twinworldPrimary-200 hover:bg-black text-black hover:text-twinworldPrimary-200",
      },
    },
    defaultVariants: {
      isDisabled: false,
    },
  }
);

export default function ProfileSaveButton({
  isDisabled = false,
  onClick,
  className,
  children,
}: Props) {
  const { isDesktop } = useDeviceBreakPoint();
  const classes = isDesktop
    ? DESKTOP_VARIANTS({ isDisabled })
    : MOBILE_VARIANTS({ isDisabled });

  return (
    <button
      type="button"
      className={`${classNameMerge(classes, className)}`}
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
}
