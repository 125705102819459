import { QueryClient } from "@tanstack/react-query";
import { useState, useRef, useEffect, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useItemActions } from "../../domains/item/hook/useItemActions";
import { useItemListActions } from "../../domains/item/hook/useItemListAction";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import Loading from "../common/Loading";
import SelectDropdown from "../common/SelectDropdown";
import LineIcon from "../common/icons/LineIcon";
import SearchIcon from "../common/icons/SearchIcon";
import SearchInput from "../common/input/SearchInput";
import ItemList from "../item/ItemList";
import MobileSearch from "../mobile/MobileSearch";
import MobileSelectDropdown from "../mobile/MobileSelectDropdown";

import { defaultValueObj, defaultItemFilter } from "./constant";
import { ITEM_LIST_TYPE, valueObj } from "./interface";

type Props = {
  categories: valueObj[];
};

const queryClient = new QueryClient();

export default function Items({ categories }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [keyword, setKeyword] = useState<string>("");
  const [value, setValue] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [selectedValue, setSelectedValue] = useState<valueObj>(defaultValueObj);
  const [selectedFilterValue, setSelectedFilterValue] =
    useState<valueObj>(defaultItemFilter);
  const [itemListType, setItemListType] = useState<string>(
    ITEM_LIST_TYPE.RELEVANCE
  );
  const [isSearchIconHover, setIsSearchIconHover] = useState(false);

  const divRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);

  const { isDesktop } = useDeviceBreakPoint();

  const { itemCategoryList = [] } = useItemActions();
  const { useInfiniteItemList } = useItemListActions();
  const { isLoading, data, fetchNextPage } = useInfiniteItemList(
    itemListType,
    keyword,
    selectedValue.idx,
    24,
    0
  );

  const itemCategories = [{ idx: "all", name: "All" }, ...itemCategoryList];

  const { search } = location;
  const urlSearchParams = new URLSearchParams(search);
  const type = urlSearchParams.get("type") as string;

  const handleSearchKeyword = (searchKeyword: string) => {
    searchKeyword = searchKeyword.trimEnd();
    setKeyword(searchKeyword);

    if (!isDesktop) {
      setShowInput(false);
    }
  };

  const handleSearchInput = () => {
    if (value && !showInput) {
      setShowInput(true);
    }

    if (value && showInput) {
      handleSearchKeyword(value);
      if (!isDesktop) {
        setShowInput(false);
      }
    }

    if (!value && !showInput) {
      setShowInput(true);
    }

    if (!value && showInput) {
      setValue("");
      setKeyword("");
      setShowInput(false);
    }
  };

  const onSubmitSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleSearchKeyword(value);
  };

  useEffect(() => {
    const handleOutsideClose = (e: { target: any }) => {
      // useRef current에 담긴 엘리먼트 바깥을 클릭 시 input 닫힘
      if (
        showInput &&
        !inputRef.current!.contains(e.target) &&
        !divRef.current!.contains(e.target) &&
        isDesktop
      ) {
        setShowInput(false);
        setValue("");
        setKeyword("");
        setSelectedValue(defaultValueObj);
        setSelectedFilterValue(defaultItemFilter);
        queryClient.invalidateQueries(["items"]);
      }
    };
    document.addEventListener("click", handleOutsideClose);

    return () => document.removeEventListener("click", handleOutsideClose);
  }, [isDesktop, showInput]);

  useEffect(() => {
    if (!type) {
      navigate(`/items?type=${ITEM_LIST_TYPE.RELEVANCE}`);
    }

    const result = selectedFilterValue.idx;

    if (result) {
      setItemListType(result);
      navigate(`/items?type=${result}`);
    }
  }, [navigate, selectedFilterValue, type]);

  return (
    <>
      <div className="flex flex-col-reverse mb-6 xl:flex-row items-center justify-center w-full xl:gap-5 xl:mb-[94px]">
        <div
          className={`${
            showInput && isDesktop ? "hidden" : "block"
          } flex flex-col xl:flex-row xl:gap-2 text-[14px]`}
        >
          {!isDesktop ? (
            <div className="flex flex-col items-center">
              <div className={`mb-5 flex self-start`}>
                <MobileSearch
                  value={value}
                  setValue={setValue}
                  handleClick={handleSearchInput}
                  handleKeyPress={onSubmitSearch}
                  placeholder="Search items..."
                  showInput={showInput}
                  keyword={keyword || "Item"}
                />
              </div>
              <MobileSelectDropdown
                list={itemCategories}
                value={selectedValue}
                setValue={setSelectedValue}
              />
              <ul className="flex items-center h-10 mt-[12px]">
                {categories.map((category, i) => (
                  <Fragment key={category.idx}>
                    <li>
                      <button
                        className={`px-[19px] py-[13px] capitalize text-[14px] ${
                          itemListType.toLowerCase() === category.idx
                            ? "text-black font-medium"
                            : "text-twinworldGray-600"
                        }  xl:hover:text-black`}
                        type="button"
                        onClick={() => {
                          setItemListType(category.idx);
                          setSelectedFilterValue(category);
                        }}
                      >
                        {category.name}
                      </button>
                    </li>
                    {i !== categories.length - 1 && (
                      <li>
                        <LineIcon />
                      </li>
                    )}
                  </Fragment>
                ))}
              </ul>
            </div>
          ) : (
            <>
              <SelectDropdown
                list={itemCategories}
                value={selectedValue}
                setValue={setSelectedValue}
                columnStyle="multiple"
              />
              <SelectDropdown
                list={categories}
                value={selectedFilterValue}
                setValue={setSelectedFilterValue}
                columnStyle="single"
              />
            </>
          )}
        </div>
        <div
          className={`${
            showInput && isDesktop ? "block animate-search" : "hidden"
          }`}
          ref={inputRef}
        >
          <SearchInput
            value={value}
            setValue={setValue}
            handleKeyPress={onSubmitSearch}
            width="w-[600px]"
            placeholder={"Search Items..."}
          />
        </div>
        <div
          onClick={handleSearchInput}
          ref={divRef}
          onMouseOver={() => setIsSearchIconHover(true)}
          onMouseOut={() => setIsSearchIconHover(false)}
          className="hidden xl:block"
        >
          <SearchIcon isHover={isSearchIconHover} />
        </div>
      </div>

      {isLoading && <Loading />}

      <div className="hidden xl:flex self-start h-10 text-[40px] font-semibold mb-[30px]">
        {keyword ?? ""}
      </div>

      <ItemList list={data} fetchNextPage={fetchNextPage} />
    </>
  );
}
