import { OrbitControls, Stage, Html } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { cva } from "class-variance-authority";
import { Suspense } from "react";

import { classNameMerge } from "../../utils/tailwindClassMerge";

import Model from "./Model";

type Props = {
  renderFileUrl: string;
  size?: string;
  rounded?: boolean;
  id?: string;
};

const ModelLoader = () => (
  <Html center>
    <div
      className="animate-spin inline-block w-60 h-60 border-[3px] border-current border-t-transparent text-landing rounded-full"
      role="status"
      aria-label="loading"
    >
      <span className="sr-only">Loading...</span>
    </div>
  </Html>
);

const VARIANTS = cva(
  `flex justify-center border border-twinworldGray-300 
  w-[339px] h-[258px] xl:w-[700px] xl:h-[522px] 
  bg-itemUpload
`,
  {
    variants: {
      rounded: {
        true: "rounded-[14px] xl:rounded-[25px]",
        false: "",
      },
    },
    defaultVariants: {
      rounded: false,
    },
  }
);

export default function Viewer3D({
  renderFileUrl,
  rounded = false,
  size = "w-[486px] h-[360px]",
  id,
}: Props) {
  const classes = classNameMerge(VARIANTS({ rounded }), size);

  return (
    <div className={classes}>
      {renderFileUrl === "" ? (
        <picture>
          <source
            srcSet="/images/upload/item_upload_default@2x.webp 2x, /images/upload/item_upload_default.webp 1x"
            type="image/webp"
          />
          <source
            srcSet="/images/upload/item_upload_default@2x.jpg 2x, /images/upload/item_upload_default.jpg 1x"
            type="image/jpg"
          />
          <img
            className={`md:rounded-10 md:h-full`}
            src="/images/upload/item_upload_default.jpg"
            alt="3d view default"
          />
        </picture>
      ) : (
        <>
          <Canvas key={id} id="3dViewer" gl={{ preserveDrawingBuffer: true }}>
            <Suspense fallback={<ModelLoader key={id} />}>
              <Stage adjustCamera environment={"sunset"}>
                <Model renderFileUrl={renderFileUrl} />
              </Stage>
              <OrbitControls makeDefault />
            </Suspense>
          </Canvas>
        </>
      )}
    </div>
  );
}
