import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthActions } from "../../domains/auth/hook/useAuthActions";
import { useSpaceActions } from "../../domains/space/hook/useSpaceActions";
import { SpaceDetails } from "../../domains/space/model/SpaceDetails";
import formatNumberToAbbreviation from "../../utils/formatNumberToAbbreviation";
import Avatar from "../common/Avatar";
import SharedLinkToast from "../common/SharedLinkToast";
import FavoriteIcon from "../common/icons/FavoriteIcon";
import LikeIcon from "../common/icons/LikeIcon";
import LineIcon from "../common/icons/LineIcon";
import UnLikeIcon from "../common/icons/UnLikeIcon";
import useModals from "../hooks/useModals";
import Divider from "../layout/Divider";
import SignInModal from "../modal/SignInModal";

const SpaceDescription = (spaceDetails: SpaceDetails) => {
  const {
    spaceId,
    spaceName,
    spaceCreator,
    spaceCreatorImage,
    spaceCode,
    isBookmarked,
    likeStatus,
    views,
    plays,
    creatorId,
  } = spaceDetails;

  const { handleLike, handleDisLike, handleBookmark } = useSpaceActions();
  const authActions = useAuthActions();
  const { openModal } = useModals();
  const [isBookmarkedHover, setIsBookmarkedHover] = useState(false);
  const [isLikeHover, setIsLikeHover] = useState(false);
  const [isDisLikeHover, setIsDisLikeHover] = useState(false);
  const navigate = useNavigate();

  const isSignIn = authActions.getLocalStorage("isSignIn");

  const handleClickLike = (event: React.MouseEvent | undefined) => {
    // prevent click event from bubbling up to parent
    if (event) {
      event.stopPropagation();
    }

    if (isSignIn) {
      handleLike(spaceId);
    } else {
      openModal(SignInModal, {});
    }
  };

  const handleClickDisLike = (event: React.MouseEvent | undefined) => {
    // prevent click event from bubbling up to parent
    if (event) {
      event.stopPropagation();
    }

    if (isSignIn) {
      handleDisLike(spaceId);
    } else {
      openModal(SignInModal, {});
    }
  };

  const handleClickBookmark = (event: React.MouseEvent | undefined) => {
    if (event) {
      event.stopPropagation();
    }

    if (isSignIn) {
      handleBookmark(spaceId);
    } else {
      openModal(SignInModal, {});
    }
  };

  const handleClickMoveUserProfile = () => {
    navigate(`/user/${creatorId}`, {
      state: { menu: "spaces" },
    });
  };

  return (
    <div className="text-left max-w-[660px] w-[660px] h-[522px] flex flex-col justify-between">
      <div
        className="relative flex w-full gap-3 mt-5 cursor-pointer"
        onClick={handleClickMoveUserProfile}
      >
        <Avatar size="lg" src={spaceCreatorImage} />
        <div className="flex flex-col gap-2">
          <h3 className="font-semibold truncate text-[18px] leading-tight text-twinworldGray-700">
            {spaceCreator}
          </h3>

          <p className="text-xs leading-none text-twinworldGray-650">
            <span>{formatNumberToAbbreviation(+views)} views</span>﹒
            <span>{formatNumberToAbbreviation(+plays)} plays</span>
          </p>
        </div>
      </div>
      <div className="mt-[42px] font-semibold text-[45px] box-border">
        {spaceName}
      </div>
      <div className="flex mt-16 text-18">
        <div className="w-full h-[60px]"></div>
      </div>
      <div className="flex flex-col mt-auto">
        <div className="text-[20px] mt-auto">{`Pin code: ${spaceCode}`}</div>
        <Divider className="h-1 border-b border-b-twinworldGray-300 mt-[24px] mb-[20px]" />
        <div className="flex items-center w-full">
          <div
            className="cursor-pointer"
            onClick={handleClickBookmark}
            onMouseEnter={() => setIsBookmarkedHover(true)}
            onMouseLeave={() => setIsBookmarkedHover(false)}
          >
            <FavoriteIcon like={isBookmarked} isHover={isBookmarkedHover} />
          </div>
          <div className="flex flex-row justify-between w-24 ml-auto mr-[45px]">
            <div
              className="cursor-pointer"
              onClick={handleClickLike}
              onMouseEnter={() => setIsLikeHover(true)}
              onMouseLeave={() => setIsLikeHover(false)}
            >
              <LikeIcon like={likeStatus === "like"} isHover={isLikeHover} />
            </div>
            <LineIcon height={28} />
            <div
              className="cursor-pointer"
              onClick={handleClickDisLike}
              onMouseEnter={() => setIsDisLikeHover(true)}
              onMouseLeave={() => setIsDisLikeHover(false)}
            >
              <UnLikeIcon
                unlike={likeStatus === "dislike"}
                isHover={isDisLikeHover}
              />
            </div>
          </div>
          <SharedLinkToast />
        </div>
      </div>
    </div>
  );
};

export default SpaceDescription;
