import { QueryClient } from "@tanstack/react-query";
import { useRef, useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import CategoryItem from "../common/CategoryItem";
import CategoryMenu from "../common/CategoryMenu";
import SearchIcon from "../common/icons/SearchIcon";
import SearchInput from "../common/input/SearchInput";
import MobileSearch from "../mobile/MobileSearch";

import {
  MY_SPACE_CATEGORY_BUTTON_LIST,
  SPACE_TYPE,
  SpaceType,
} from "./interface";

const MY_SPACE_PATHNAME = "myspace";

const CATEGORY_BUTTON_LIST = [
  {
    label: "Featured",
    path: "/spaces?type=featured",
  },
  {
    label: "Trending",
    path: "/spaces?type=trending",
  },
  {
    label: "Live",
    path: "/spaces?type=live",
  },
  {
    label: "Recent",
    path: "/spaces?type=recent",
  },
];

type Props = {
  keyword: string;
  setKeyword: (keyword: string) => void;
};

const queryClient = new QueryClient();

const getSpaceTypeFromType = (type: string) => {
  const key = type.toUpperCase() as keyof typeof SPACE_TYPE;
  return SPACE_TYPE[key] as SpaceType;
};

export default function SpaceCategorySearch({ keyword, setKeyword }: Props) {
  const { pathname, search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const type = urlSearchParams.get("type");

  const [showInput, setShowInput] = useState(false);
  const [isSearchIconHover, setIsSearchIconHover] = useState(false);

  const [value, setValue] = useState("");

  const divRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);

  const { isDesktop } = useDeviceBreakPoint();

  const spaceCategoryButtonList = pathname.includes(MY_SPACE_PATHNAME)
    ? MY_SPACE_CATEGORY_BUTTON_LIST
    : CATEGORY_BUTTON_LIST;

  const handleSearchKeyword = (searchKeyword: string) => {
    const trimSearchKeyword = searchKeyword.trimEnd();
    setKeyword(trimSearchKeyword);

    if (!isDesktop) {
      setShowInput(false);
    }
  };

  const onSubmitSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleSearchKeyword(value);
  };

  const handleSearchInput = () => {
    if (value && !showInput) {
      setShowInput(true);
    }

    if (value && showInput) {
      handleSearchKeyword(value);

      if (!isDesktop) {
        setShowInput(false);
      }
    }

    if (!value && !showInput) {
      setShowInput(true);
    }

    if (!value && showInput) {
      setValue("");
      setKeyword("");
      setShowInput(false);
    }
  };

  useEffect(() => {
    const handleOutsideClose = (e: { target: any }) => {
      // useRef current에 담긴 엘리먼트 바깥을 클릭 시 input 닫힘
      if (isDesktop) {
        if (
          showInput &&
          !inputRef.current!.contains(e.target) &&
          !divRef.current!.contains(e.target)
        ) {
          setShowInput(false);
          setValue("");
          setKeyword("");
          queryClient.invalidateQueries(["spaces", "searchSpaceList"]);
        }
      }
    };

    document.addEventListener("click", handleOutsideClose);

    return () => document.removeEventListener("click", handleOutsideClose);
  }, [isDesktop, setKeyword, showInput]);

  useEffect(() => {
    if (type && value && keyword) {
      const result = getSpaceTypeFromType(type);

      if (result) {
        setValue("");
      }
    }
  }, [type]);

  return (
    <div className="flex items-center justify-center w-full gap-5 mb-[16px] xl:mb-[58px]">
      {isDesktop ? (
        <>
          <div className={`${showInput ? "hidden" : "block"}`}>
            <CategoryMenu list={spaceCategoryButtonList} />
          </div>
          <div
            className={`${showInput ? "block animate-search" : "hidden"}`}
            ref={inputRef}
          >
            <SearchInput
              value={value}
              setValue={setValue}
              handleKeyPress={onSubmitSearch}
              width={"w-[528px]"}
              height={"h-[64px]"}
              placeholder={"Search Spaces..."}
            />
          </div>
          <div
            onClick={handleSearchInput}
            ref={divRef}
            onMouseOver={() => setIsSearchIconHover(true)}
            onMouseOut={() => setIsSearchIconHover(false)}
          >
            <SearchIcon isHover={isSearchIconHover} />
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <div className={`flex self-start`}>
            <MobileSearch
              value={value}
              setValue={setValue}
              handleClick={handleSearchInput}
              handleKeyPress={onSubmitSearch}
              placeholder="Search spaces..."
              showInput={showInput}
              keyword={keyword || "Space"}
            />
          </div>
          <div className="w-[375px] px-[18px] py-[14px] box-border">
            <ul className="h-[60px] flex flex-row items-center justify-center gap-[10px]">
              {CATEGORY_BUTTON_LIST.map((category) => (
                <Link key={category.label} to={category.path}>
                  <li>
                    <CategoryItem label={category.label} path={category.path} />
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
