import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";

import { useAuthActions } from "../../auth/hook/useAuthActions";
import userApi from "../api";
import { PatchInfo, PatchProfile } from "../interface";

interface ErrorMessages {
  changePassword: string;
  changeUsername: string;
}

// TODO useSettingAction 에서 사용하던 프로필 수정과 관련된 로직을 그대로 복사
// profile 업데이트가 useProfileActions으로 완전히 이관되면 useSettingActions쪽 내용을 삭제한다.
export const useProfileActions = () => {
  const queryClient = useQueryClient();
  const authActions = useAuthActions();

  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({
    changePassword: "",
    changeUsername: "",
  });

  const variable = useRef({
    isDoubleClick: false,
  });

  const useGetMyProfile = () => {
    const {
      isLoading: isMyProfileLoading,
      data: profile,
      refetch: refetchMyProfile,
    } = useQuery(["profile"], userApi.getMyProfile, {
      enabled: !!localStorage.getItem("accessToken"),
    });

    return {
      isMyProfileLoading,
      profile,
      refetchMyProfile,
    };
  };

  const useGetUserProfile = (userId: string) => {
    const { isLoading, data, isError } = useQuery(["profile", { userId }], () =>
      userApi.getUserProfile(userId)
    );

    return {
      isLoading,
      isError,
      data,
    };
  };

  const updateUserProfileMutation = useMutation(
    (info: PatchInfo) => userApi.patchUserProfile(info),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["profile"] });
        queryClient.invalidateQueries({ queryKey: ["userSetting"] });
      },
    }
  );

  const updateUserProfile = async (info: PatchInfo) => {
    const { mutateAsync } = updateUserProfileMutation;
    await mutateAsync(info).catch((error) => {
      throw error;
    });
  };

  const useGetFollowerList = () => {
    const { data } = useQuery(["follower"], userApi.getFollowerList, {
      refetchOnWindowFocus: false,
      enabled: authActions.isSignInSuccess,
      onSuccess: (data) => {
        if (data === undefined) {
          data = [];
        }
      },
      onError: (error) => {
        console.error(error);
      },
    });

    return data;
  };

  const useGetFollowingList = () => {
    const { data } = useQuery(["following"], userApi.getFollowingList, {
      refetchOnWindowFocus: false,
      enabled: authActions.isSignInSuccess,
      onSuccess: (data) => {
        if (data === undefined) {
          data = [];
        }
      },
      onError: (error) => {
        console.error(error);
      },
    });

    return data;
  };

  const patchUserProfile = useMutation(
    async (userProfile: PatchProfile) => {
      await userApi.patchUserProfile(userProfile);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["profile"] });

        // birthDate 가 없던 social login 유저가 생년월일을 입력하면 isSignIn = true 가 되면서 완전한 로그인이 된다.
        if (!authActions.getLocalStorage("isSignIn")) {
          authActions.setLocalStorage("isSignIn", "true");
        }
      },
    }
  );

  const handleSubmitProfile = async (userSetting: PatchProfile) => {
    try {
      if (variable.current.isDoubleClick) {
        return;
      }

      variable.current.isDoubleClick = true;
      await patchUserProfile.mutate(userSetting);
      variable.current.isDoubleClick = false;
    } catch (err: any) {
      variable.current.isDoubleClick = false;

      if (
        err.response.data.message ===
        "UPDATE_SETTING_ERROR: REGISTERED_NICKNAME"
      ) {
        setErrorMessages((prevState: ErrorMessages) => ({
          ...prevState,
          changeUsername: "Please use different username. Already exist.",
        }));
      }
    }
  };

  return {
    useGetMyProfile,
    useGetUserProfile,
    updateUserProfile,
    useGetFollowingList,
    useGetFollowerList,
    handleSubmitProfile,
    errorMessages,
    setErrorMessages,
  };
};
