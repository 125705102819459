import useDeviceBreakPoint from "../../../hooks/useDeviceBreakPoint";

import { Menu } from "./types";

type Props = {
  selectedMenu: string;
  setSelectedMenu: React.Dispatch<React.SetStateAction<string>>;
  menuList: Menu[];
};

export default function TabMenu({
  menuList,
  selectedMenu,
  setSelectedMenu,
}: Props) {
  const { isDesktop } = useDeviceBreakPoint();

  const buttonClasses = (selected: boolean) => {
    if (isDesktop) {
      return `hover:bg-black hover:text-white ${
        selected ? "bg-black text-white" : "bg-white text-twinworldGray-700"
      } `;
    }

    return `border-[1.5px] border-black font-medium ${
      selected ? "bg-[#D4E5FF]" : "bg-white"
    } `;
  };

  return (
    <section className="flex gap-2 rounded-full xl:gap-4 xl:p-2 xl:bg-twinworldSecondary-200">
      {menuList.map(({ label }) => (
        <button
          key={label}
          className={`px-[18px] py-3 xl:px-[26px] xl:py-4 text-[13px] xl:text-[14px] xl:leading-[16px] capitalize rounded-full font-medium ${buttonClasses(
            selectedMenu === label
          )} xl:hover:transition-background-color xl:hover:duration-300 xl:hover:bg-black xl:hover:text-white`}
          type="button"
          onClick={() => setSelectedMenu(label)}
        >
          {label}
        </button>
      ))}
    </section>
  );
}
