import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import itemApi from "../api";

export const useItemActions = () => {
  const queryClient = useQueryClient();

  const { data: itemCategoryList } = useQuery(
    ["itemCategoryList"],
    itemApi.getItemCategoryList
  );

  const { mutate: likeMutate } = useMutation(
    async (itemId: string) => {
      await itemApi.updateLikeItem(itemId);
    },
    {
      onSuccess: (data, itemId) => {
        queryClient.invalidateQueries(["itemDetails", itemId]);
      },
    }
  );

  const handleLike = (itemId: string) => {
    likeMutate(itemId);
  };

  const { mutate: disLikeMutate } = useMutation(
    async (itemId: string) => {
      await itemApi.updateDisLikeItem(itemId);
    },
    {
      onSuccess: (data, itemId) => {
        queryClient.invalidateQueries(["itemDetails", itemId]);
      },
    }
  );

  const handleDisLike = (itemId: string) => {
    disLikeMutate(itemId);
  };

  const useItemDetails = (itemId: string) => {
    const {
      isLoading,
      isError,
      data: itemDetails,
      error: itemDetailsError,
    } = useQuery(
      ["itemDetails", itemId],
      () => itemApi.getItemDetails(itemId),
      {
        onSuccess: (data) => data,
        onError: (error: any) => error,
      }
    );

    return { isLoading, isError, itemDetails, itemDetailsError };
  };

  return {
    itemCategoryList,
    handleLike,
    handleDisLike,
    useItemDetails,
  };
};
