import { AxiosError } from "axios";

import { client } from "../../utils/axiosInstance";

import {
  ResetPasswordRequestInfo,
  UserEmailSignInInfo,
  UserSignUpInfo,
} from "./interface";
import CoinsHistory, {
  CoinsSummary,
  CoinsUsageHistory,
} from "./model/CoinsHistory";

const socialSignIn = async (
  oauthAccessToken: string,
  provider: string,
  service: string
) => await client({
    url: `/auth/oauth-sign-in`,
    method: "POST",
    data: {
      service,
      provider,
      oauthAccessToken,
    },
  }).catch((error: AxiosError) => {
    throw error;
  });

const localSignOut = async (refreshToken: string) => {
  await client({
    url: "/auth/sign-out",
    method: "POST",
    data: {
      refreshToken,
    },
  });
};

const refreshToken = async (refreshToken: string) => {
  const response = await client({
    url: "/auth/renew-token",
    method: "POST",
    data: {
      refreshToken,
    },
  }).catch((error: AxiosError) => {
    throw error;
  });

  const accessToken = response.data;

  if (!accessToken) {
    throw new Error("refreshToken error");
  }

  return accessToken;
};

const getQRSignInToken = async (): Promise<string> => {
  const response = await client({
    url: "/auth/create-pin",
    method: "GET",
  });

  const qrSignInToken = response.data.data.pin;

  if (!qrSignInToken) {
    throw new Error("getQRSignInToken error: QRSignInToken Empty.");
  }

  return qrSignInToken;
};

const getCoinsSummary = async (): Promise<CoinsSummary> => {
  let result;
  try {
    const response = await client({
      url: `/auth/coins`,
      method: "GET",
    });
    result = new CoinsHistory(response.data.data);
  } catch (error) {
    result = new CoinsHistory(undefined);
  }
  return result.coinsSummary;
};

const getCoinsUsageHistory = async (
  take = 50,
  skip = 0
): Promise<CoinsUsageHistory[]> => {
  let result;
  try {
    const response = await client({
      url: `/auth/coins?take=${take}&skip=${skip}`,
      method: "GET",
    });
    result = new CoinsHistory(response.data.data);
  } catch (error) {
    result = new CoinsHistory(undefined);
  }
  return result.usageHistory;
};

const signUp = async (info: UserSignUpInfo): Promise<void> => {
  await client({
    url: "/auth/email-sign-up",
    method: "POST",
    data: info,
  }).catch((error: AxiosError) => {
    throw error;
  });
};

const resendSignUpConfirmMail = async (email: string): Promise<void> => {
  await client({
    url: "/auth/email-sign-up/confirm-resend",
    method: "POST",
    data: {
      email,
    },
  }).catch((error: AxiosError) => {
    throw error;
  });
};

const emailSignIn = async (
  info: UserEmailSignInInfo
): Promise<{
  message: string | null;
  data: { accessToken: string; refreshToken: string };
}> => {
  const response = await client({
    url: "/auth/email-sign-in",
    method: "POST",
    data: info,
  }).catch((error: AxiosError) => {
    throw error;
  });

  return response.data;
};

const sendResetPasswordConfirmMail = async (email: string): Promise<void> => {
  await client({
    url: "/auth/request-reset-password",
    method: "POST",
    data: {
      email,
    },
  }).catch((error: AxiosError) => {
    throw error;
  });
};

const resetPassword = async (info: ResetPasswordRequestInfo): Promise<void> => {
  await client({
    url: "/auth/reset-password",
    method: "POST",
    data: info,
  }).catch((error: AxiosError) => {
    throw error;
  });
};

const changePassword = async (currentPassword: string, newPassword: string) => {
  await client({
    url: "/auth/change-password",
    method: "POST",
    data: {
      currentPassword,
      newPassword,
    },
  });
};

const authApi = {
  socialSignIn,
  emailSignIn,
  localSignOut,
  refreshToken,
  getQRSignInToken,
  signUp,
  resendSignUpConfirmMail,
  sendResetPasswordConfirmMail,
  resetPassword,
  changePassword,
  getCoinsSummary,
  getCoinsUsageHistory,
};

export default authApi;
