type Props = {
  disLike: boolean;
};

export default function MobileDisLikeIcon({ disLike }: Props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.18182 18.3H12.0655L11.0291 23.327L10.9964 23.679C10.9964 24.13 11.1818 24.548 11.4764 24.845L12.6327 26L19.8109 18.751C20.2145 18.355 20.4545 17.805 20.4545 17.2V6.2C20.4545 4.99 19.4727 4 18.2727 4H8.45454C7.54909 4 6.77455 4.55 6.44727 5.342L3.15273 13.097C3.05454 13.35 3 13.614 3 13.9V16.1C3 17.31 3.98182 18.3 5.18182 18.3ZM5.18182 13.9L8.45454 6.2H18.2727V17.2L13.5382 21.974L14.7491 16.1H5.18182V13.9ZM27 17.2H22.6364V4H27V17.2Z"
        fill={disLike ? "black" : "#B7B7B7"}
      />
    </svg>
  );
}
