import Modal from "../common/Modal";
import ModalClose from "../common/icons/ModalClose";

type Props = {
  closeModal: () => void;
  itemThumbnail: string;
  itemName: string;
  handleUpdate: () => void;
};

const defaultSrc = "/images/profile/default_profile.webp";

export default function ItemEditUpdateModal({
  closeModal,
  itemThumbnail,
  itemName,
  handleUpdate,
}: Props) {
  const handleImgError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = defaultSrc;
  };

  const close = (
    <div className="flex items-center justify-end pt-[26px] pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  const title = (
    <div className="flex justify-center w-full mt-[30px] text-[24px] font-semibold px-[118px] leading-8">
      Do you want to update below item?
    </div>
  );

  return (
    <Modal
      closeModal={closeModal}
      title={title}
      close={close}
      maxWidth="w-[480px]"
    >
      <div className="flex flex-col items-center mt-4 mb-[16px]">
        <img
          src={itemThumbnail}
          alt="item"
          className="mx-[75px] w-[248px] h-[198px] border border-twinworldGray-3 rounded-[25px]"
          onError={handleImgError}
        />
        <span className="mt-[22px] text-[20px] text-twinworldGray-770 leading-6 font-semibold">
          {itemName}
        </span>
        <div className="flex flex-col items-center gap-[18px] mt-[41px] mb-[60px]">
          <button
            className="px-[130px] py-[21px] text-black bg-twinworldPrimary-200 font-semibold text-[16px] rounded-full border-2 border-black hover:text-twinworldPrimary-200 hover:bg-black"
            onClick={handleUpdate}
          >
            Update
          </button>
          <button
            className="px-[130px] py-[21px] text-black bg-white font-semibold text-[16px] rounded-full border-2 border-black hover:text-twinworldPrimary-200 hover:bg-black"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}
