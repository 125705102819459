export default function MobileMenuIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 8H22" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M5 15H25" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path d="M5 22H18" stroke="black" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
