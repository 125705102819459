import { useMutation } from "@tanstack/react-query";
import { useRef } from "react";

import itemApi from "../api";
import { uploadGLBItemInfo } from "../interface";


export const useItemUploadActions = () => {
  // post를 보내고 res를 받아야하기 때문에, mutate(동기) 대신에 mutateAsync(비동기)로 사용
  const { mutateAsync } = useMutation(itemApi.uploadItem);

  const variable = useRef({
    isDoubleClick: false,
  });

  const uploadItem = (data: uploadGLBItemInfo) => {
    if (variable.current.isDoubleClick) {
      return;
    }

    variable.current.isDoubleClick = true;

    const uploadedItem = mutateAsync(data, {
      onSuccess: () => {
        variable.current.isDoubleClick = false;
      },
      onError(error) {
        variable.current.isDoubleClick = false;
      },
    });

    return uploadedItem;
  };

  return {
    uploadItem,
  };
};
