import { useNavigate } from "react-router-dom";

const UploadYourItem = () => {
  const navigate = useNavigate();

  return (
    <section className="leading-relaxed text-black mt-[62px] font-nexon pl-6 pr-[18px] xl:px-0">
      <p className="font-semibold text-[24px] xl:text-[40px] font-poppins">
        Upload your item
      </p>

      <p className="font-medium text-[20px] xl:text-[24px] mt-[36px] xl:mt-[70px] text-twinworldOrange font-poppins">
        Preparing My Own Item
      </p>

      <div className="xl:ml-[30px] mt-6 xl:mt-[30px]">
        <p className="text-[16px] xl:text-[20px] font-semibold xl:font-medium font-poppins xl:font-nexon">
          1. Concept
        </p>

        <div className="mt-4 text-[14px] xl:text-[18px] leading-6 xl:leading-8 ml-[15px] xl:ml-[19px] text-twinworldGray-770 xl:text-twinworldGray-800 font-poppins xl:font-nexon">
          Craft and upload an item with your distinct concept to TwinWorld.
          <p>
            Share your creatively designed item with an original and unique
            concept, and engage with other users!
          </p>
        </div>

        <p className="font-semibold xl:font-medium text-[16px] xl:text-[20px] mt-6 xl:mt-[30px] font-poppins xl:font-nexon">
          2. File Size and Format
        </p>

        <div className="mt-4 text-[14px] xl:text-[18px] leading-6 xl:leading-8 text-twinworldGray-770 xl:text-twinworldGray-800 ml-5 font-normal font-poppins xl:font-nexon">
          You can upload content with a maximum size of 5MB.
          <p className="mt-2 xl:mt-0">
            Please use the embedded glb file extension. Failure to adhere to
            size or format requirements may limit your item upload.
          </p>
          <p>
            To ensure the thumbnail is displayed appropriately, the recommended
            object size is 7m x 7m x 7m.
          </p>
          <p className="mt-2 xl:mt-0">
            The minimum upload size for an object is 1.2m x 1.2m x 1.2m. Make
            sure to appropriately enlarge the thumbnail before uploading!
          </p>
        </div>

        <p className="font-semibold xl:font-medium text-[16px] xl:text-[20px] mt-6 xl:mt-[30px] font-poppins xl:font-nexon">
          3. Crafting Guidelines
        </p>

        <div className="mt-4 text-[14px] xl:text-[18px] leading-6 xl:leading-8 text-twinworldGray-770 xl:text-twinworldGray-800 ml-5 font-normal font-poppins xl:font-nexon">
          Please note that one animation is allowed per item
        </div>

        <p className="font-semibold xl:font-medium text-[20px] xl:text-[24px] mt-[36px] xl:mt-[70px] text-twinworldOrange font-poppins">
          Uploading My Item
        </p>

        <p className="font-semibold xl:font-medium text-[16px] xl:text-[20px] mt-6 xl:mt-[30px] font-poppins xl:font-nexon">
          4. Title
        </p>

        <div className="mt-4 text-[14px] xl:text-[18px] leading-6 xl:leading-8 text-twinworldGray-770 xl:text-twinworldGray-800 ml-5 font-normal font-poppins xl:font-nexon">
          Choose an appropriate title for your creation.
          <p className="mt-2 xl:mt-0">
            Include keywords that accurately describe your item. This will help
            your item be easily found by a wider audience when using the search
            function.
          </p>
        </div>

        <p className="font-semibold xl:font-medium text-[16px] xl:text-[20px] mt-6 xl:mt-[30px] font-poppins xl:font-nexon">
          5. Category and Tags
        </p>

        <p className="mt-4 text-[14px] xl:text-[18px] leading-6 xl:leading-8 text-twinworldGray-770 xl:text-twinworldGray-800 ml-5 font-normal font-poppins xl:font-nexon">
          Upload your item by selecting the desired category from TwinWorld's
          basic item category list.
        </p>

        <div className="ml-5 text-[14px] xl:text-[18px] mt-6 xl:mt-[20px] leading-[14px] xl:leading-7 font-semibold xl:font-medium text-twinworldOrange font-poppins xl:font-nexon">
          Category List:
          <p className="text-twinworldGray-770 xl:text-twinworldGray-800 mt-2 xl:mt-[10px] text-[13px] xl:text-[14px] font-poppins xl:font-nexon font-normal leading-[22px]">
            Animal / Architecture / Art & Abstract / Characters / Fashion / Food
            / Heritage / Nature & Plants / Science & Tech / Sports / Vehicles /
            Weapons / Electronics / Interior
          </p>
        </div>

        <p className="font-semibold xl:font-medium text-[16px] xl:text-[20px] mt-6 xl:mt-[30px] font-poppins xl:font-nexon">
          6. Final Thumbnail Check
        </p>

        <div
          className="mt-4 ml-5 underline cursor-pointer text-[14px] xl:text-[18px] text-twinworldGray-770 xl:text-twinworldGray-800 font-normal xl:hover:font-medium leading-6 font-poppins xl:font-nexon"
          onClick={() => navigate("#thumbnail-image-guide")}
        >
          Thumbnail guide available here
        </div>

        <p className="font-semibold xl:font-medium text-[16px] xl:text-[20px] mt-6 xl:mt-[30px] font-poppins">
          7. Save / Publish
        </p>

        <div className="mt-4 ml-5 text-[14px] xl:text-[18px] leading-6 xl:eading-8 text-twinworldGray-770 xl:text-twinworldGray-800 font-normal font-poppins xl:font-nexon">
          <p>
            After confirming the final thumbnail, select 'Save / Publish' to
            upload your item.
          </p>
          <p className="mt-2 xl:mt-0">
            Check your uploaded items in ‘My Box’ after uploading. Now you can
            freely arrange your items from your Space
          </p>
        </div>
      </div>
    </section>
  );
};

export default UploadYourItem;
