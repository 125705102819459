import { useState } from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";

import EyeIcon from "../icons/EyeIcon";
import EyeSlashIcon from "../icons/EyeSlashIcon";

type Props = {
  name: string;
  isError?: boolean;
  onClick?: () => void;
  options?: {
    required: boolean;
    pattern?: { value: RegExp; message: string };
    minLength?: { value: number; message: string };
    maxLength?: { value: number; message: string };
    max?: { value: number; message: string };
    min?: { value: number; message: string };
  };
  placeholder?: string;
  register: UseFormRegister<FieldValues | any>;
  type: "text" | "password" | "email";
};

export default function LoginInput({
  name,
  isError,
  onClick,
  options,
  placeholder,
  register,
  type,
}: Props) {
  const [inputType, setInputType] = useState<string>(type);

  const isPasswordInput = type === "password";

  const handleInputType = () =>
    setInputType((prev) => (prev === "text" ? "password" : "text"));

  return (
    <label className="relative block w-full" htmlFor={name}>
      <input
        className={`py-[16px] px-[16px] md:px-7 rounded-full text-[14px] font-normal md:text-[16px] w-full box-border border ${
          isError
            ? "text-twinworldRed border-twinworldRed"
            : "border-twinworldGray-350 focus:border-twinworldGray-770"
        } `}
        type={inputType}
        onClick={onClick}
        placeholder={placeholder}
        maxLength={type === "password" ? 20 : undefined}
        {...register(name, { ...options })}
      />
      <div className="absolute flex items-center w-[24px] h-[24px] right-[20px] bottom-[15px]">
        {isPasswordInput && (
          <div className="cursor-pointer" onClick={handleInputType}>
            {inputType === "text" ? <EyeIcon /> : <EyeSlashIcon />}
          </div>
        )}
      </div>
    </label>
  );
}
