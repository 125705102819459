import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Avatar from "../common/Avatar";
import { ConversionStatus } from "../item/type";
import ItemThumbnail from "../thumbnail/ItemThumbnail";

type Props = {
  id: string;
  title: string;
  creator: string;
  uploaderId: string;
  thumbnailUrl: string;
  spriteUrl: string;
  avatarSrc: string;
  conversionStatus: ConversionStatus;
  onClick: () => void;
  size?: "sm" | "md";
  rounded?: string;
};

export default function ItemCard({
  id,
  title,
  creator,
  uploaderId,
  thumbnailUrl,
  avatarSrc,
  spriteUrl,
  onClick,
  conversionStatus,
  size = "md",
  rounded,
}: Props) {
  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  const handleClickAvatar = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/user/${uploaderId}`);
  };

  return (
    <div
      className={`${
        size === "sm" ? "w-[140px] h-[184px]" : "w-[164px] h-[208px]"
      } md:w-[248px] md:h-[310px] flex flex-col cursor-pointer`}
      onClick={onClick}
      key={id}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ItemThumbnail
        width={`${size === "sm" ? "w-[140px]" : "w-[164px]"} md:w-[248px]`}
        height={`${size === "sm" ? "h-[140px]" : "h-[164px]"} md:h-[248px]`}
        src={thumbnailUrl}
        alt={title}
        spriteUrl={spriteUrl}
        isHovered={isHovered}
        rounded={rounded}
        border={true}
        defaultSrc={"/images/card/item_card_default.webp"}
        conversionStatus={conversionStatus}
      />

      <div className="w-full mt-[8px] md:mt-[22px] flex text-twinworldGray-700">
        <Avatar size="sm" src={avatarSrc} onClick={handleClickAvatar} />
        <div className="w-[115px] h-[36px] md:w-[208px] md:h-[40px] pl-[10px] md:pl-[12px] box-border">
          <p
            className={`${
              size === "sm" ? "w-[90px]" : "w-[114px]"
            } md:w-[147px] font-medium md:font-semibold truncate text-[14px] md:text-[15px] leading-[17px] md:leading-[18px] whitespace-nowrap text-ellipsis`}
          >
            {title}
          </p>
          <p
            className={`${
              size === "sm" ? "w-[90px]" : "w-[114px]"
            }  md:w-[147px] mt-[3px] md:mt-[5px] text-[13px] md:text-[14px] leading-[16px] md:leading-[17px] truncate text-ellipsis`}
          >
            {creator}
          </p>
        </div>
      </div>
    </div>
  );
}
