import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SpaceList from "../components/SpaceList";
import SpaceCategorySearch from "../components/SpaceList/SpaceCategorySearch";
import { SPACE_TYPE, SpaceType } from "../components/SpaceList/interface";
import ScrollToTopButton from "../components/common/Button/ScrollToTopButton";
import MainWrapper from "../components/common/MainWrapper";
import SpaceFeatureList from "../components/space/SpaceFeatureList";
import SpaceGroupsAllList from "../components/space/SpaceGroupsAllList";

const getSpaceTypeFromType = (type: string) => {
  const key = type.toUpperCase() as keyof typeof SPACE_TYPE;
  return SPACE_TYPE[key] as SpaceType;
};

export default function Spaces() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const type = urlSearchParams.get("type");

  const [selectedCategory, setSelectedCategory] = useState<string>(
    SPACE_TYPE.TRENDING
  );
  const [spaceType, setSpaceType] = useState<SpaceType>(
    SPACE_TYPE.TRENDING as SpaceType
  );
  const [keyword, setKeyword] = useState<string>("");

  const handleKeyword = (keyword: string) => {
    setKeyword(keyword);
  };

  const groupId = urlSearchParams.get("groupId");

  useEffect(() => {
    if (!type) {
      navigate(`/spaces?type=${SPACE_TYPE.TRENDING}`);
    } else {
      const result = getSpaceTypeFromType(type);

      if (result) {
        setSelectedCategory(type);
        setSpaceType(result);
        setKeyword("");
      }
    }
  }, [navigate, type]);

  return (
    <MainWrapper>
      <SpaceCategorySearch keyword={keyword} setKeyword={handleKeyword} />

      {selectedCategory === SPACE_TYPE.FEATURED && groupId && (
        <SpaceGroupsAllList groupId={groupId} keyword={keyword} />
      )}

      {selectedCategory === SPACE_TYPE.FEATURED && groupId === null && (
        <SpaceFeatureList keyword={keyword} />
      )}

      {selectedCategory !== SPACE_TYPE.FEATURED && (
        <SpaceList
          type={spaceType}
          selectedCategory={selectedCategory}
          keyword={keyword}
        />
      )}

      <ScrollToTopButton />
    </MainWrapper>
  );
}
