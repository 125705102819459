import { ConversionStatus } from "../../../components/item/type";
import { Space } from "../interface";

export class SpaceCard {
  spaceId;

  spaceName;

  spaceImage;

  spaceCreator;

  spaceCreatorId;

  spaceCreatorImage;

  isBookmarked;

  views;

  plays;

  liveUserCount;

  conversionStatus;

  constructor(data: Space) {
    this.spaceId = data.id;
    this.spaceName = data.name;
    this.spaceImage = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${data.thumbnail}?size=original&fileType=space-thumbnail`;
    this.spaceCreatorId = data.creatorId;
    this.spaceCreatorImage = data.creatorAvatarImageUrl;
    this.spaceCreator = data.creatorNickname;
    this.isBookmarked = data.isBookmarked;
    this.views = +data.viewCount ?? 0;
    this.plays = +data.joinCount ?? 0;
    this.liveUserCount = data.liveUserCount;
    this.conversionStatus = this.getRandomConversionStatus();
  }

  getRandomConversionStatus() {
    const statuses = ["converting", "success", "failed"];
    const randomIndex = Math.floor(Math.random() * statuses.length);
    return statuses[randomIndex] as ConversionStatus;
  }
}
