import { useNavigate } from "react-router-dom";

import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import formatNumberToAbbreviation from "../../utils/formatNumberToAbbreviation";
import Avatar from "../common/Avatar";
import Image from "../common/Image";
import AudienceOverview from "../mobile/AudienceOverview";
import SpaceLiveUser from "../space/SpaceLiveUser";

type Props = {
  id: string;
  thumbnailSrc: string;
  avatarSrc: string;
  title: string;
  creator: string;
  creatorId: string;
  views: number;
  plays: number;
  liveUserCount: number;
  onClick: (id: string) => void;
  rounded?: string;
};

export default function SpaceCard({
  id,
  thumbnailSrc,
  avatarSrc,
  title,
  creator,
  creatorId,
  views,
  plays,
  liveUserCount,
  onClick,
  rounded = "rounded-none",
}: Props) {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceBreakPoint();

  const handleClickAvatar = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/user/${creatorId}`);
  };

  return (
    <section
      className="flex flex-col w-[210px] h-[220px] xl:w-[345px] xl:h-[341px] cursor-pointer relative"
      onClick={() => onClick(id)}
    >
      <Image
        border
        rounded={rounded}
        src={thumbnailSrc}
        alt="space image"
        width="w-[210px] xl:w-[345px]"
        height="h-[156px] xl:h-[258px]"
        defaultSrc="/images/space/space_card_default.webp"
        hoverEffect={"xl:hover:border-[#2C4B99] xl:hover:border-2"}
      />

      {liveUserCount > 0 && (
        <div className="absolute top-[9px] right-[10px] xl:top-[11px] xl:right-[18px] w-fit">
          <SpaceLiveUser userCount={liveUserCount} />
        </div>
      )}

      <div className="relative flex w-full mt-[10px] xl:mt-[22px]">
        <Avatar size="sm" src={avatarSrc} onClick={handleClickAvatar} />
        <div className="flex flex-col gap-[5px] w-[170px] xl:w-[305px] pl-[12px]">
          <h3 className="w-[136px] xl:w-[281px] font-semibold truncate text-[14px] xl:text-[15px] leading-[17px] xl:leading-[18px] text-twinworldGray-700">
            {title}
          </h3>
          <p className="w-[136px] xl:w-[281px] text-[13px] xl:text-[14px] leading-[16px] truncate text-twinworldGray-700">
            {creator}
          </p>
          <div className="w-[136px] xl:w-[281px] text-[12px] xl:text-[13px] leading-[15px] xl:leading-[16px] truncate text-twinworldGray-650">
            {isDesktop ? (
              <>
                <span>{formatNumberToAbbreviation(views)} views</span>﹒
                <span>{formatNumberToAbbreviation(plays)} plays</span>
              </>
            ) : (
              <AudienceOverview views={views} plays={plays} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
