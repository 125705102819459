import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import { FetchList } from "../../../types/common";
import { checkSpecialChar } from "../../../utils/lib";
import spaceApi from "../api";
import { SpaceListParams, CreatorSpaceListParams } from "../interface";

export const useSpaceActions = () => {
  let spaceId: string;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const spaceSearchKeyWord = params.get("search") || "";

  const useInfiniteSearchSpaceList = (
    type: string,
    keyword: string,
    take: number,
    skip: number
  ) => {
    const { isLoading, fetchNextPage, hasNextPage, isError, data, refetch } =
      useInfiniteQuery(
        ["spaces", "searchSpaceList", { type, keyword, take, skip }],
        ({ pageParam = 1 }) => {
          const result = spaceApi.getInfiniteSearchSpaceList(
            keyword,
            type,
            take,
            (pageParam - 1) * take
          );

          return result;
        },
        {
          getNextPageParam: (lastPage, allPages) => {
            const nextPage = allPages.length + 1;
            return lastPage.length < take ? undefined : nextPage;
          },
        }
      );

    return { isLoading, fetchNextPage, hasNextPage, isError, data, refetch };
  };

  const useFeaturedSpaceList = () => {
    const { isLoading, data } = useQuery(
      ["spaces", "featuredSpaceList"],
      spaceApi.getFeaturedSpaceList
    );

    return { isLoading, data };
  };

  const useInfiniteGroupsSpaceList = (
    groupId: string,
    take: number,
    skip: number
  ) => {
    const { isLoading, fetchNextPage, hasNextPage, isError, data, refetch } =
      useInfiniteQuery(
        ["spaces", "groupsSpaceList", { groupId, take, skip }],
        ({ pageParam = 1 }) => {
          const result = spaceApi.getInfiniteGroupsSpaceList(
            groupId,
            take,
            (pageParam - 1) * take
          );

          return result;
        },
        {
          getNextPageParam: (lastPage, allPages) => {
            const nextPage = allPages.length + 1;
            return lastPage.length < take ? undefined : nextPage;
          },
        }
      );

    return { isLoading, fetchNextPage, hasNextPage, isError, data, refetch };
  };

  const useSpaceList = (params: SpaceListParams) => {
    const { type, take = 24, skip = 0 } = params;
    const { isLoading, data } = useQuery(
      ["spaces", "spaceList", { type, take, skip }],
      () => spaceApi.getSpaceList({ type, take, skip }),
      {
        enabled: !checkSpecialChar(spaceSearchKeyWord),
      }
    );

    return { isLoading, data };
  };

  const useCreatorSpaceList = (params: CreatorSpaceListParams) => {
    const { take = 24, skip = 0, creatorId } = params;
    const { isLoading, data, fetchNextPage } = useInfiniteQuery(
      ["spaces", "creatorSpaceList", { creatorId, take, skip }],
      ({ pageParam = 1 }) => {
        const skip = (pageParam - 1) * take;
        const result = spaceApi.getCreatorSpaceList({ creatorId, take, skip });
        return result;
      },
      {
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length + 1;
          return lastPage.length < take ? undefined : nextPage;
        },
      }
    );

    return { isLoading, data, fetchNextPage };
  };

  const useSpaceDetails = (spaceId = "") => {
    const {
      isLoading,
      isError,
      data: spaceDetails,
      error: spaceDetailsError,
    } = useQuery(
      ["aSpace", "spaceDetails", { spaceId }],
      () => spaceApi.getSpaceDetails(spaceId),
      {
        onSuccess: (data) => data,
        onError: (error: any) => error,
      }
    );
    return {
      isLoading,
      isError,
      spaceDetails,
      spaceDetailsError,
    };
  };

  // bookmark와 like시에 캐시된 모든 space data들에 영향을 끼치기때문에 모두 지워져아하고
  // 각각의 space 경우에는 각각의 space data 캐시만 지우면 됨
  // 🧐 하나의 함수로 여러개의 쿼리를 지운다
  const queryClient = useQueryClient();
  const { mutateAsync: bookmarkMutateAsync } = useMutation(
    spaceApi.updateBookmarkSpace,
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(["spaces"]);
        queryClient.invalidateQueries([
          "aSpace",
          "spaceDetails",
          { spaceId: variables },
        ]);
      },
    }
  );

  const handleBookmark = async (id: string) => {
    spaceId = id;
    await bookmarkMutateAsync(spaceId);
  };

  const { mutateAsync: likeMutateAsync } = useMutation(
    spaceApi.updateLikeSpace,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[1] === "spaceDetails",
        });
        queryClient.invalidateQueries({
          predicate: (query: any) =>
            query.queryKey[0] === "aSpace" &&
            query.queryKey[2]?.spaceId === spaceId,
        });
      },
    }
  );

  const handleLike = async (id: string) => {
    await likeMutateAsync(id);
  };

  const { mutateAsync: disLikeMutateAsync } = useMutation(
    spaceApi.updateDisLikeSpace,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[1] === "spaceDetails",
        });
        queryClient.invalidateQueries({
          predicate: (query: any) =>
            query.queryKey[0] === "aSpace" &&
            query.queryKey[2]?.spaceId === spaceId,
        });
      },
    }
  );

  const handleDisLike = async (id: string) => {
    await disLikeMutateAsync(id);
  };

  const mySpaceSearchKeyWord =
    new URLSearchParams(location.search).get("search") || "";

  const useMyVisitedSpaceList = ({ take, skip }: FetchList) => {
    const { isLoading, data } = useQuery(
      ["spaces", "myVisitedSpaceList", { mySpaceSearchKeyWord, take, skip }],
      () => spaceApi.getMyVisitedSpaceList(mySpaceSearchKeyWord, take, skip),
      {
        enabled: !checkSpecialChar(mySpaceSearchKeyWord),
      }
    );

    return { isLoading, data };
  };

  const useMyOwnSpaceList = ({ take, skip }: FetchList) => {
    const { isLoading, data } = useQuery(
      ["spaces", "myOwnSpaceList", { mySpaceSearchKeyWord, take, skip }],
      () => spaceApi.getMyOwnSpaceList(mySpaceSearchKeyWord, take, skip),
      {
        enabled: !checkSpecialChar(mySpaceSearchKeyWord),
      }
    );

    return { isLoading, data };
  };

  const useMyFavoriteSpaceList = ({ take, skip }: FetchList) => {
    const { isLoading, data } = useQuery(
      ["spaces", "myFavoriteSpaceList", { mySpaceSearchKeyWord, take, skip }],
      () => spaceApi.getMyFavoriteSpaceList(mySpaceSearchKeyWord, take, skip),
      {
        enabled: !checkSpecialChar(mySpaceSearchKeyWord),
      }
    );
    return { isLoading, data };
  };

  return {
    useSpaceList,
    useCreatorSpaceList,
    useSpaceDetails,
    useInfiniteSearchSpaceList,
    useFeaturedSpaceList,
    useInfiniteGroupsSpaceList,
    handleBookmark,
    handleLike,
    handleDisLike,
    useMyVisitedSpaceList,
    useMyOwnSpaceList,
    useMyFavoriteSpaceList,
  };
};
