import { Fragment, useEffect, useRef, useState } from "react";

import Frame from "./Frame";
import ImaginableItem from "./ImaginableItem";
import { Imaginable } from "./types";

const LIST: Imaginable[] = [
  {
    title: "Exhibition",
    description:
      "A new dimension of mixed reality exhibitions that interact with art objects.",
    imageSrc: "/images/landing/anything/1.webp",
  },
  {
    title: "Education",
    description: (
      <Fragment>
        Live education programs using immersive 3D objects in fields such as
        <br />
        architecture, design, medicine, and language.
      </Fragment>
    ),
    imageSrc: "/images/landing/anything/2.webp",
  },
  {
    title: "Community",
    description: (
      <Fragment>
        Forming a community where users from different locations share
        <br />
        cultural values, knowledge, and fun experiences.
      </Fragment>
    ),
    imageSrc: "/images/landing/anything/3.webp",
  },
  {
    title: "Artwork",
    description: (
      <Fragment>
        Creating various art pieces such as sculptures, paintings,
        <br />
        and animations in a 3D environment.
      </Fragment>
    ),
    imageSrc: "/images/landing/anything/4.webp",
  },
  {
    title: "Business",
    description: (
      <Fragment>
        Engaging in business activities with employees from around the world,
        <br />
        including meetings, product demonstrations, education, and training.
      </Fragment>
    ),
    imageSrc: "/images/landing/anything/5.webp",
  },
  {
    title: "Entertainment",
    description: (
      <Fragment>
        Immersive experiences that captivate your senses, such as games,
        <br />
        concerts, parties, events, and explorations.
      </Fragment>
    ),
    imageSrc: "/images/landing/anything/6.webp",
  },
];

export default function ImaginableList() {
  const [hoveredId, setHoveredId] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const ref = useRef<HTMLDivElement>(null);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const el = ref.current as HTMLDivElement;
    const rect = el.getBoundingClientRect();
    setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  useEffect(() => {
    const callback: IntersectionObserverCallback = (entries, observer) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          LIST.forEach((item) => {
            const img = new Image();
            img.src = item.imageSrc;
          });
        }
      });
    };

    const intersectionObserver = new IntersectionObserver(callback);
    const el = ref.current as HTMLDivElement;
    intersectionObserver.observe(el);

    return () => intersectionObserver.disconnect();
  }, []);

  return (
    <section
      className="relative w-full px-16"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseMove={handleMouseMove}
      ref={ref}
    >
      {LIST.map((item, index) => (
        <ImaginableItem
          key={index}
          index={index}
          hoveredId={hoveredId}
          setIsHovered={(id: number) => setHoveredId(id)}
          imaginable={item}
        />
      ))}
      <Frame
        isDisplayed={isHovered}
        hoveredItem={LIST[hoveredId ?? 0]}
        position={position}
      />
    </section>
  );
}
