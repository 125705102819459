import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthContext } from "../../contexts/AuthContext";
import { useModelContext } from "../../contexts/ModelContext";
import { useAuthActions } from "../../domains/auth/hook/useAuthActions";
import { useItemActions } from "../../domains/item/hook/useItemActions";
import { useMyBoxActions } from "../../domains/item/hook/useMyBoxActions";
import convertFileSizeToString from "../../utils/convertFileSizeToString";
import formatNumberToAbbreviation from "../../utils/formatNumberToAbbreviation";
import Avatar from "../common/Avatar";
import SharedLinkToast from "../common/SharedLinkToast";
import EditIcon from "../common/icons/EditIcon";
import FavoriteIcon from "../common/icons/FavoriteIcon";
import LikeIcon from "../common/icons/LikeIcon";
import LineIcon from "../common/icons/LineIcon";
import UnLikeIcon from "../common/icons/UnLikeIcon";
import useModals from "../hooks/useModals";
import Divider from "../layout/Divider";
import SignInModal from "../modal/SignInModal";

import ItemInformation from "./ItemInformation";
import { ItemDetailsInfo } from "./type";

type ItemDetailDataProps = ItemDetailsInfo & {
  userId: string;
};

type ItemDescriptionProps = {
  itemDetailsData: ItemDetailDataProps;
};

export default function ItemDescription({
  itemDetailsData,
}: ItemDescriptionProps) {
  const { isAnimation } = useModelContext();
  const { profile } = useAuthContext();
  const { openModal } = useModals();
  const [isFavoriteHover, setIsFavoriteHover] = useState(false);
  const [isLikeHover, setIsLikeHover] = useState(false);
  const [isDisLikeHover, setIsDisLikeHover] = useState(false);

  const {
    itemId,
    name,
    creator,
    categoryName,
    isFavorite,
    likeStatus,
    fileSize,
    uploaderImage,
    conversionStatus,
    vertices,
  } = itemDetailsData;
  const navigate = useNavigate();
  const { handleLike, handleDisLike } = useItemActions();

  const authActions = useAuthActions();
  const { saveToMyBox, deleteFromMyBox } = useMyBoxActions();
  const isSignIn = authActions.getLocalStorage("isSignIn");

  const changeCategoryName = (categoryName: string) => categoryName;

  const itemInfo = [
    {
      title: "Category",
      value: changeCategoryName(categoryName),
    },
    {
      title: "Vertices",
      value:
        conversionStatus === "success"
          ? formatNumberToAbbreviation(vertices)
          : "-",
    },
    {
      title: "Size",
      value:
        conversionStatus === "success"
          ? convertFileSizeToString(fileSize)
          : "-",
    },
    {
      title: "Animation",
      value:
        conversionStatus === "success" ? (isAnimation ? "Yes" : "No") : "-",
    },
  ];

  const handleClickLike = (event: React.MouseEvent | undefined) => {
    // prevent click event from bubbling up to parent
    if (event) {
      event.stopPropagation();
    }

    if (isSignIn) {
      handleLike(itemId);
    } else {
      openModal(SignInModal, {});
    }
  };

  const handleClickDisLike = (event: React.MouseEvent | undefined) => {
    // prevent click event from bubbling up to parent
    if (event) {
      event.stopPropagation();
    }

    if (isSignIn) {
      handleDisLike(itemId);
    } else {
      openModal(SignInModal, {});
    }
  };

  const handleClickSaveItem = (event: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }

    if (isSignIn) {
      if (isFavorite) {
        deleteFromMyBox(itemId).catch((error) => {
          alert(`${error.response.data.message}`);
        });
      } else {
        saveToMyBox(itemId).catch((error) => {
          alert(`${error.response.data.message}`);
        });
      }
    } else {
      openModal(SignInModal, {});
    }
  };

  const handleClickMoveUserProfile = () => {
    navigate(`/user/${itemDetailsData.creatorId}`, {
      state: { menu: "items" },
    });
  };

  return (
    <div className="text-left max-w-[660px] w-[660px] h-[522px] flex flex-col">
      <div
        className="relative flex items-center w-full gap-3 cursor-pointer "
        onClick={handleClickMoveUserProfile}
      >
        <Avatar size="lg" src={uploaderImage} />
        <div className="flex flex-col gap-2">
          <h3 className="font-semibold truncate text-[18px] leading-tight text-twinworldGray-700 hover:underline">
            {creator}
          </h3>
        </div>
      </div>
      <div className="font-semibold text-[45px] leading-[55px] text-black overflow-hidden break-words mt-[58px]">
        {name}
      </div>
      <ItemInformation data={itemInfo} />
      <Divider className="h-1 mt-auto border-b border-b-twinworldGray-300" />
      <div className="flex items-center w-full mt-5">
        <div className="cursor-pointer">
          {profile?.userId === itemDetailsData.creatorId ? (
            <button
              className="py-3 px-4 flex items-center justify-center text-[16px] border border-1.5 border-black box-border rounded-full gap-[6px] hover:bg-twinworldPrimary-200 bg-inherit"
              onClick={() => navigate(`/items/${itemDetailsData.itemId}/edit`)}
            >
              Edit
              <EditIcon />
            </button>
          ) : (
            <div
              onClick={handleClickSaveItem}
              onMouseEnter={() => setIsFavoriteHover(true)}
              onMouseLeave={() => setIsFavoriteHover(false)}
            >
              <FavoriteIcon like={isFavorite} isHover={isFavoriteHover} />
            </div>
          )}
        </div>
        <div className="flex flex-row justify-between w-24 ml-auto mr-[45px]">
          <div
            className="cursor-pointer"
            onClick={handleClickLike}
            onMouseEnter={() => setIsLikeHover(true)}
            onMouseLeave={() => setIsLikeHover(false)}
          >
            <LikeIcon like={likeStatus === "like"} isHover={isLikeHover} />
          </div>
          <LineIcon height={28} />
          <div
            className="cursor-pointer"
            onClick={handleClickDisLike}
            onMouseEnter={() => setIsDisLikeHover(true)}
            onMouseLeave={() => setIsDisLikeHover(false)}
          >
            <UnLikeIcon
              unlike={likeStatus === "dislike"}
              isHover={isDisLikeHover}
            />
          </div>
        </div>
        <SharedLinkToast />
      </div>
    </div>
  );
}
