import MobileRightArrowIcon from "./MobileRightArrowIcon";

export default function MobileMetaQuertBtn() {
  return (
    <button
      type="button"
      className="absolute bottom-[25px] rounded-full bg-[#71C2FF]/[.28] backdrop-blur w-[290px] py-[13px] pl-3 pr-1 flex items-center justify-center"
      onClick={() =>
        window.open(
          "https://www.meta.com/ko-kr/experiences/6464922583573383/?ranking_trace=116836404732608_6464922583573383_SKYLINEWEB_33225171-95f5-49a1-9889-a97a2fdb183f",
          "_blank"
        )
      }
    >
      <span className="text-white text-[18px] font-semibold capitalize mr-3">
        Available on Meta Quest
      </span>
      <MobileRightArrowIcon />
    </button>
  );
}
