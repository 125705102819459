import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { useAuthActions } from "../../domains/auth/hook/useAuthActions";
import { UserSignUpInfo } from "../../domains/auth/interface";
import { REGEX_FORMAT } from "../../utils/regex";
import Modal from "../common/Modal";
import ModalClose from "../common/icons/ModalClose";
import LoginInput from "../common/input/LoginInput";

import EmailVerificationModal from "./EmailVerificationModal";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function SignUpModal({ isOpen, closeModal }: Props) {
  const [isSignUpSuccess, setIsSignUpSuccess] = useState(false);
  const { signUp, errorMessages, setErrorMessages } = useAuthActions();

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      terms: false,
      email: "",
      password: "",
    },
  });

  const watchTerms = watch("terms");

  const isInputValid =
    Object.keys(dirtyFields).length > 0 &&
    Object.keys(errors).length === 0 &&
    watchTerms;

  const onSubmit = async (data: FieldValues) => {
    if (!isInputValid) {
      return;
    }
    const { email, password } = data;
    const signUpInfo: UserSignUpInfo = {
      serviceName: "Twinworld",
      email,
      password,
    };

    await signUp(signUpInfo).then(() => {
      setIsSignUpSuccess(true);
    });
  };

  const close = (
    <div className="flex items-center justify-end pt-[26px] pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  const title = (
    <div className="flex justify-center w-full mt-6 mb-[42px] text-[24px] font-semibold">
      Create a New Account
    </div>
  );

  return (
    <Modal
      maxWidth="max-w-[480px]"
      isOpen={isOpen}
      title={title}
      close={close}
      closeModal={closeModal}
    >
      {isSignUpSuccess ? (
        <EmailVerificationModal
          email={getValues("email")}
          closeModal={closeModal}
        />
      ) : (
        <form
          className="flex flex-col items-center gap-4 mx-20 mb-14"
          onSubmit={handleSubmit(onSubmit)}
        >
          <LoginInput
            type="email"
            name="email"
            register={register}
            placeholder="Email"
            options={{
              required: true,
              maxLength: {
                value: 50,
                message: "* Please check your email again",
              },
              pattern: {
                value: REGEX_FORMAT.EMAIL,
                message: "* Please check your email again",
              },
            }}
            onClick={() => {
              setErrorMessages((prev) => ({
                ...prev,
                accountError: "",
              }));
            }}
            isError={
              !!errors?.email ||
              !!errorMessages.accountError ||
              !!errorMessages.signUp
            }
          />
          {(errors?.email ||
            errorMessages.accountError ||
            errorMessages.signUp) && (
            <p className="ml-[36px] text-twinworldRed text-[14px] text-left w-full flex white-space">
              {errorMessages.accountError ||
                errorMessages.signUp ||
                errors?.email?.message?.toString()}
            </p>
          )}

          <LoginInput
            type="password"
            name="password"
            register={register}
            placeholder="Password"
            options={{
              required: true,
              minLength: {
                value: 8,
                message: "* Password should be more than 8 letters",
              },
              pattern: {
                value: REGEX_FORMAT.PASSWORD,
                message:
                  "* Ensure that password contains both letters and numbers.",
              },
            }}
            onClick={() => {
              setErrorMessages((prev) => ({
                ...prev,
                wrongPassword: "",
              }));
            }}
            isError={
              !!errors?.password ||
              !!errorMessages.wrongPassword ||
              !!errorMessages.accountError
            }
          />
          {(errors?.password || errorMessages.wrongPassword) && (
            <p className="ml-[36px] text-twinworldRed text-[14px] text-left w-full flex white-space">
              {errorMessages.wrongPassword ||
                errors?.password?.message?.toString()}
            </p>
          )}
          <div className="flex items-center w-full h-full mt-[26px] ml-[30px]">
            <input
              type="checkbox"
              {...register("terms")}
              className="w-[16px] h-[16px] mr-2.5 cursor-pointer"
            />

            <div
              onClick={() => window.open("/terms-and-conditions/service")}
              className="mr-3 text-landing text-[14px] font-medium cursor-pointer underline"
            >
              Terms and conditions
            </div>
          </div>
          <button
            type="submit"
            className="flex items-center justify-center w-[320px] h-[58px] px-[80px] py-[21px] border-2 border-black bg-twinworldPrimary-200 rounded-full mt-[64px] text-[16px] font-semibold hover:bg-black hover:text-twinworldPrimary-200 
          disabled:border-twinworldGray-500 disabled:bg-[#D7E0ED] disabled:text-twinworldGray-500 disabled:text-[16px] disabled:font-semibold disabled:cursor-not-allowed"
            disabled={!isInputValid}
          >
            Sign Up
          </button>
        </form>
      )}
    </Modal>
  );
}
