import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import CoinsHistoryDetails from "../components/coins/CoinsHistoryDetails";
import RedeemDetails from "../components/coins/RedeemDetails";
import Loading from "../components/common/Loading";
import MainWrapper from "../components/common/MainWrapper";
import TabMenu from "../components/common/menu/TabMenu";
import { useAuthContext } from "../contexts/AuthContext";
import { useAuthActions } from "../domains/auth/hook/useAuthActions";

const MENU_LIST = [{ label: "redeem" }, { label: "coins history" }];

type Location = {
  state: {
    menu: string;
  };
};

export const MyCoins = () => {
  const { profile } = useAuthContext();
  const { state } = useLocation() as Location;
  const { useGetCoinsSummary } = useAuthActions();
  const { data, isLoading } = useGetCoinsSummary();

  const [menu, setMenu] = useState("redeem");

  useEffect(() => {
    if (state) {
      setMenu(state.menu);
    }
  }, [state]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MainWrapper>
      <div className="flex flex-col w-full">
        <div className="flex flex-col items-center justify-center w-full gap-8">
          <TabMenu
            menuList={MENU_LIST}
            selectedMenu={menu}
            setSelectedMenu={setMenu}
          />
        </div>
      </div>
      <div className="flex flex-col w-full max-w-[1289px] mx-auto">
        {menu === "redeem" && profile && data && (
          <RedeemDetails coinsSummary={data} />
        )}

        {menu === "coins history" && profile && data && (
          <CoinsHistoryDetails coinsSummary={data} />
        )}
      </div>
    </MainWrapper>
  );
};
