import { useNavigate } from "react-router-dom";

import { useAuthActions } from "../../domains/auth/hook/useAuthActions";
import { useSpaceActions } from "../../domains/space/hook/useSpaceActions";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import FavoriteIcon from "../common/icons/FavoriteIcon";
import useModals from "../hooks/useModals";
import SignInModal from "../modal/SignInModal";

import MobileLikeDisLike from "./MobileLikeDisLike";
import MobileSharedLinkToast from "./MobileSharedToast";

type Props = {
  spaceId: string;
  likeStatus: "like" | "dislike" | "none";
  isBookmarked: boolean;
};

export default function MobileSpaceReactionToolbar({
  spaceId,
  likeStatus,
  isBookmarked,
}: Props) {
  const navigate = useNavigate();
  const authActions = useAuthActions();
  const isSignIn = authActions.getLocalStorage("isSignIn");
  const { handleLike, handleDisLike, handleBookmark } = useSpaceActions();
  const { isMobile } = useDeviceBreakPoint();
  const { openModal } = useModals();

  const handleClickBookmark = (e: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }

    if (isSignIn) {
      handleBookmark(spaceId);
      return;
    }

    if (isMobile) {
      navigate("/sign-in", { state: { redirect: `/spaces/${spaceId}` } });
      return;
    }

    openModal(SignInModal, {});
  };

  return (
    <div className="flex items-center justify-between pt-[20px] pb-[4px] mt-[20px] border-t border-twinworldGray-200">
      <div className="cursor-pointer" onClick={handleClickBookmark}>
        <FavoriteIcon like={isBookmarked} isDesktop={false} />
      </div>
      <div className="flex items-center justify-between w-[133px] h-[34px]">
        <MobileLikeDisLike
          type="spaces"
          id={spaceId}
          likeStatus={likeStatus}
          handleClickLike={() => handleLike(spaceId)}
          handleClickDisLike={() => handleDisLike(spaceId)}
        />

        <MobileSharedLinkToast />
      </div>
    </div>
  );
}
