import { CoinsSummary as CoinsSummaryType } from "../../domains/auth/model/CoinsHistory";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import MobileCoinsHistory from "../mobile/MobileCoinsHistory";

import CoinsSummary from "./CoinsSummary";
import CoinsUsageHistoryTable from "./CoinsUsageHistoryTable";

type Props = {
  coinsSummary: CoinsSummaryType;
};

export default function CoinsHistoryDetails({ coinsSummary }: Props) {
  const { isDesktop } = useDeviceBreakPoint();

  return (
    <section className="flex flex-col min-w-[339px] w-full px-[18px] md:px-[24px] xl:px-0 gap-0 mx-auto">
      {isDesktop && (
        <h2 className="text-[45px] leading-[45px] font-semibold mt-[60px]">
          Coins History
        </h2>
      )}
      <CoinsSummary coinsSummary={coinsSummary} />
      {isDesktop ? <CoinsUsageHistoryTable /> : <MobileCoinsHistory />}
    </section>
  );
}
