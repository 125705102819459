import { useQuery, useInfiniteQuery } from "@tanstack/react-query";

import { FetchList } from "../../../types/common";
import { checkSpecialChar } from "../../../utils/lib";
import itemApi from "../api";

export const useItemListActions = () => {
  const useItemList = ({
    condition,
    keyword,
    categoryId,
    take,
    skip,
    uploaderId,
  }: FetchList) => {
    const { isLoading, data } = useQuery(
      ["itemList", { condition, keyword, categoryId, take, skip, uploaderId }],
      () =>
        itemApi.getItemList({
          condition,
          keyword,
          categoryId,
          take,
          skip,
          uploaderId,
        }),
      {
        enabled: !!condition && !checkSpecialChar(keyword || ""),
      }
    );

    return { isLoading, data };
  };

  const useInfiniteItemList = (
    condition: string,
    keyword: string,
    categoryId: string,
    take: number,
    skip: number
  ) => {
    const { isLoading, fetchNextPage, hasNextPage, isError, data, refetch } =
      useInfiniteQuery(
        ["itemList", { condition, keyword, categoryId, take, skip }],
        ({ pageParam = 1 }) => {
          const result = itemApi.getInfiniteSearchItemList(
            condition,
            keyword,
            categoryId,
            take, // take * pageParam
            (pageParam - 1) * take
          );
          return result;
        },
        {
          getNextPageParam: (lastPage, allPages) => {
            const nextPage = allPages.length + 1;
            return lastPage.length < take ? undefined : nextPage;
          },
        }
      );

    return { isLoading, fetchNextPage, hasNextPage, isError, data, refetch };
  };

  const useInfiniteUserItemList = (
    userId: string,
    take: number,
    skip: number
  ) => {
    const { isLoading, fetchNextPage, hasNextPage, isError, data, refetch } =
      useInfiniteQuery(
        ["userItemList", { userId, take, skip }],
        ({ pageParam = 1 }) => {
          const result = itemApi.getInfiniteSearchUserItemList(
            userId,
            take, // take * pageParam
            (pageParam - 1) * take
          );
          return result;
        },
        {
          getNextPageParam: (lastPage, allPages) => {
            const nextPage = allPages.length + 1;
            return lastPage.length < take ? undefined : nextPage;
          },
        }
      );

    return { isLoading, fetchNextPage, hasNextPage, isError, data, refetch };
  };

  return {
    useItemList,
    useInfiniteItemList,
    useInfiniteUserItemList,
  };
};
