import {
  useMutation,
  useQueryClient,
  useInfiniteQuery,
} from "@tanstack/react-query";

import itemApi from "../api";

export const useMyBoxActions = () => {
  const queryClient = useQueryClient();
  /* mybox 의 all, my items (내가 업로드한 아이템)
   페이지 OR 탭들을 보여줄 때 필요한 api 만 호출하면서도, search 검색어를 queryKey 에 집어넣어 검색이 
   trigger 될 때 바로 query 를 다시 호출하도록 하기 위해 url location 을 이용한다. 
  */

  const useInfiniteMyBoxList = (
    keyword: string,
    take: number,
    skip: number
  ) => {
    const { isLoading, fetchNextPage, hasNextPage, isError, data, refetch } =
      useInfiniteQuery(
        ["mybox", keyword, take, skip],
        ({ pageParam = 1 }) => {
          const result = itemApi.getInfiniteSearchMyBoxList(
            keyword,
            420, // take * pageParam,
            0 // (pageParam - 1) * take
          );

          return result;
        },
        {
          getNextPageParam: (lastPage, allPages) => {
            const nextPage = allPages.length + 1;
            return lastPage.length < take ? undefined : nextPage;
          },
          cacheTime: 1000,
          staleTime: 1000,
        }
      );

    return { isLoading, fetchNextPage, hasNextPage, isError, data, refetch };
  };

  const useInfiniteMyItemList = (
    keyword: string,
    take: number,
    skip: number
  ) => {
    const { isLoading, fetchNextPage, hasNextPage, isError, data, refetch } =
      useInfiniteQuery(
        ["myItemList", keyword, take, skip],
        ({ pageParam = 1 }) => {
          const result = itemApi.getInfiniteSearchMyItemList(
            keyword,
            420, // take * pageParam,
            0 // (pageParam - 1) * take
          );

          return result;
        },
        {
          getNextPageParam: (lastPage, allPages) => {
            const nextPage = allPages.length + 1;
            return lastPage.length < take ? undefined : nextPage;
          },
          cacheTime: 1000,
          staleTime: 1000,
        }
      );

    return { isLoading, fetchNextPage, hasNextPage, isError, data, refetch };
  };

  const { mutateAsync: deleteFromMyBoxMutateAsync } = useMutation(
    itemApi.deleteFromMyBox
  );

  const deleteFromMyBox = (itemId: string) => {
    const response = deleteFromMyBoxMutateAsync(itemId, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["itemDetails", itemId] });
      },
      onError: (error) => {
        throw error;
      },
    });

    return response;
  };

  const { mutateAsync: saveToMyBoxMutateAsync } = useMutation(
    itemApi.saveToMyBox
  );

  const saveToMyBox = (itemId: string) => {
    const response = saveToMyBoxMutateAsync(itemId, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["itemDetails", itemId] });
      },
      onError: (error) => {
        throw error;
      },
    });

    return response;
  };

  return {
    useInfiniteMyBoxList,
    useInfiniteMyItemList,
    deleteFromMyBox,
    saveToMyBox,
  };
};
