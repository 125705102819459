import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import Devices from "../landing/devices";

import MobileAnything from "./landing/MobileAnything";
import MobileDevice from "./landing/MobileDevice";
import MobileExperience from "./landing/MobileExperience";
import MobileIntroduce from "./landing/MobileIntroduce";
import MobileSpace from "./landing/MobileSpace";
import MobileValue from "./landing/MobileValue";

export default function MobileLanding() {
  const { isMobile } = useDeviceBreakPoint();

  return (
    <section className="w-full mb-[140px] border-t border-twinworldGray-200">
      <MobileIntroduce />
      <MobileSpace />
      <MobileValue />
      <MobileAnything />
      <MobileExperience />
      {isMobile ? <MobileDevice /> : <Devices />}
    </section>
  );
}
