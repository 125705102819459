import type { SpaceCard as SpaceCardModel } from "../../../domains/space/model/SpaceCard";

import { useNavigate } from "react-router-dom";

import useDeviceBreakPoint from "../../../hooks/useDeviceBreakPoint";
import SpaceCard from "../../card/SpaceCard";
import MobileMoreArrow from "../../mobile/icons/MobileMoreArrow";
import MoreArrow from "../icons/MoreArrow";

import EmptyList from "./EmptyList";

type Props = {
  title: string;
  list: SpaceCardModel[] | undefined;
  moreButtonLink: string;
  userNickname?: string;
  maximumCount?: number;
  navigateState?: string;
};

export default function SpaceGroupList({
  title,
  moreButtonLink,
  list,
  userNickname = "You",
  maximumCount = 4,
  navigateState,
}: Props) {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceBreakPoint();

  const handleClickMore = () =>
    navigateState
      ? navigate(`${moreButtonLink}`, { state: { menu: navigateState } })
      : navigate(`${moreButtonLink}`);

  const handleClickCard = (spaceId: string) => navigate(`/spaces/${spaceId}`);

  return (
    <section>
      <div className="flex items-center justify-between">
        <h3 className="w-[222px] md:w-fit truncate text-[18px] xl:text-[40px] font-semibold leading-[21px] xl:leading-[43px] pl-[18px] md:pl-[24px] xl:pl-0">
          {title}
        </h3>
        {list && list.length >= maximumCount && moreButtonLink && (
          <button
            className="pr-[18px] xl:pr-0"
            type="button"
            onClick={handleClickMore}
          >
            {isDesktop ? <MoreArrow /> : <MobileMoreArrow />}
          </button>
        )}
      </div>

      {(list === undefined || list.length === 0) && (
        <div className="mt-[30px] xl:mt-[120px]">
          <EmptyList type="space" title={userNickname} />
        </div>
      )}

      {list && list.length > 0 && (
        <ul className="flex gap-x-[10px] xl:gap-x-[20px] mt-[16px] xl:mt-[30px] px-[18px] md:px-[24px] xl:px-0 overflow-x-auto h-[225px] xl:h-[347px] scrollbar-hidden">
          {list.slice(0, maximumCount).map((space) => (
            <li key={space.spaceId}>
              <SpaceCard
                id={space.spaceId}
                thumbnailSrc={space.spaceImage}
                avatarSrc={space.spaceCreatorImage}
                title={space.spaceName}
                creator={space.spaceCreator}
                creatorId={space.spaceCreatorId}
                views={space.views}
                plays={space.plays}
                onClick={() => handleClickCard(space.spaceId)}
                liveUserCount={space.liveUserCount}
                rounded={isDesktop ? "rounded-[20px]" : "rounded-[10px]"}
              />
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}
